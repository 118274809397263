import {
  Grid,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import AddUserModal from "../usuarios/components/addUserModal"
import { useState } from "react";




const Users = () => {
  const [users, setUsers] = useState([
    { id: 1, name: "Juan Pérez", email: "juan@ejemplo.com", role: "Administrador" },
    { id: 2, name: "Ana García", email: "ana@ejemplo.com", role: "Editor" },
    { id: 3, name: "Carlos Rodríguez", email: "carlos@ejemplo.com", role: "Usuario" },
    { id: 4, name: "María López", email: "maria@ejemplo.com", role: "Editor" },
    { id: 5, name: "Pedro Sánchez", email: "pedro@ejemplo.com", role: "Usuario" },
  ]);
  
  const [modalOpen, setModalOpen] = useState(false);
  
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  
  const addUser = (newUser) => {
    const newId = Math.max(...users.map(user => user.id || 0)) + 1;
    setUsers([...users, { id: newId, ...newUser }]);
  };

  return (
    <Grid container padding={5} style={{ height: "100vh" }}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={8} style={{ textAlign: "start" }}>
          <h1 style={{ color: "#1976d2", margin: "0px" }}>Usuarios</h1>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={3} style={{ textAlign: "start" }}>
          <TextField
            placeholder="Buscar usuarios..."
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={9} style={{ textAlign: "end" }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleOpenModal}
          >
            Agregar usuario
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} style={{ backgroundColor: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "25%" }}>Nombre</TableCell>
                <TableCell style={{ width: "30%" }}>Email</TableCell>
                <TableCell style={{ width: "20%" }}>Rol</TableCell>
                <TableCell style={{ width: "15%" }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    <a
                      href={`mailto:${user.email}`}
                      style={{ color: "#8B4513" }}
                    >
                      {user.email}
                    </a>
                  </TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <Tooltip title="Editar">
                      <IconButton
                        aria-label="editar"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton
                        aria-label="eliminar"
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <AddUserModal 
        open={modalOpen} 
        handleClose={handleCloseModal} 
        addUser={addUser}
      />
    </Grid>
  );
};

export default Users;