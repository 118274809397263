import React from "react";
import { useState, useEffect } from "react";
import ingressService from "../../../services/ingressService";
import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import * as styles from "./styles";
import CustomMaterialTable from "../../../components/MaterialTable";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../../../components/customModal";
import ModalIngreso from "./modalIngreso";
import { Link } from "react-router-dom";
import userService from "../../../services/userService";

const initialAlert = {
  severity: "success",
  message: "",
};

export default function CrearIngreso() {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [textEntered, setTextEntered] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [openModal, setOpenMOdal] = useState(false);
  const [amountEntered, setAmountEntered] = useState("");
  const [cantidadIngresadaErrors, setCantidadIngresadaErrors] = useState({});
  const [inputFocus, setInputFocus] = useState(null);
  const [deposits, setDeposits] = useState([]);

  const columns = [
    {
      title: "SKU",
      field: "sku_id",
      width: 50,
    },
    {
      title: "Descripción",
      field: "name",
      width: 300,
    },
    {
      title: "Stock actual",
      field: "stock",
      width: 40,
    },
    {
      title: "Stock a ingresar",
      field: "stock_in",
      width: 40,
      render: (rowData) => (
        <TextField
          variant="outlined"
          key={rowData?.sku_id}
          size="small"
          type="text"
          style={{
            width: "80px",
          }}
          value={rowData.amountEntered === null ? "" : rowData.amountEntered}
          onChange={(event) => handleQuantityChange(event, rowData.sku_id)}
          inputProps={{
            pattern: "[0-9]*",
            title: "Ingrese solo números",
          }}
          autoFocus={inputFocus === rowData.sku}
        />
      ),
    },
    {
      title: "Acciones",
      field: "acciones",
      width: 100,
      render: (rowData) => (
        <DeleteIcon
          variant="outlined"
          color="red"
          onClick={() => handleRemoveProduct(rowData.sku_id)}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];
  useEffect(() => {
    fetchDepositos();
  }, []);

  useEffect(() => {
    if (textEntered.length >= 3) {
      filterProductsByText(textEntered);
    } else {
      setFilteredProducts([]);
    }
  }, [textEntered]);

  const fetchDepositos = async () => {
    const result = await userService.getDeposits();
    setDeposits(result.data);
  };

  const filterProductsByText = async (text) => {
    setIsSearching(true);
    try {
      const lowercasedText = text.toLowerCase();
      const res = await ingressService.getInventory(textEntered);
      const products = res?.data?.results || [];
      const filtered = products.filter(
        (product) =>
          product.name.toLowerCase().includes(lowercasedText) ||
          product.sku_id.toLowerCase().includes(lowercasedText)
      );
      setFilteredProducts(filtered);
    } catch (error) {
      console.error("Error al filtrar productos:", error);
      setFilteredProducts([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleProductSelect = (event, value) => {
    if (value) {
      const existentProduct = selectedProducts.find(
        (selectedProduct) => selectedProduct.sku_id === value.sku_id
      );

      if (!existentProduct) {
        setSelectedProducts((prevProductos) => [
          { ...value, amountEntered: null },
          ...prevProductos,
        ]);
      }
    }
  };

  const handleQuantityChange = (event, sku) => {
    const value = event.target.value.trim();
    const errors = { ...cantidadIngresadaErrors };

    if (value === "" || (!isNaN(value) && parseInt(value, 10) >= 0)) {
      errors[sku] = "";
      setSelectedProducts((prevProductos) =>
        prevProductos.map((producto) =>
          producto.sku_id === sku
            ? {
                ...producto,
                amountEntered: value === "" ? null : parseInt(value, 10),
              }
            : producto
        )
      );
    } else {
      errors[sku] = "Campo requerido";
    }

    setCantidadIngresadaErrors(errors);
    setInputFocus(sku);
  };

  const handleRemoveProduct = (sku_id) => {
    setSelectedProducts((prevProductos) =>
      prevProductos.filter((product) => product.sku_id !== sku_id)
    );
    if (selectedProducts.length === 1) {
      handleCloseModal();
    }
  };

  const canConfirm = () => {
    const hasSelectedProducts = selectedProducts.length > 0;
    const hasInvalidQuantity = selectedProducts.some(
      (producto) => !producto.amountEntered || producto.amountEntered <= 0
    );

    return hasSelectedProducts && !hasInvalidQuantity;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOpenModal = () => setOpenMOdal(true);
  const handleCloseModal = () => setOpenMOdal(false);

  const calcularTotalUnidadesIngresadas = () => {
    const total = selectedProducts.reduce(
      (accumulator, producto) => accumulator + (producto.amountEntered || 0),
      0
    );
    setAmountEntered(total);
  };

  useEffect(() => {
    calcularTotalUnidadesIngresadas();
  }, [selectedProducts]);

  return (
    <styles.RootContainer>
      <Grid
        container
        item
        xs={10}
        justifyContent={"center"}
        style={{ alignContent: "baseline" }}
      >
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: "2vh" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid xs={8} style={{ textAlign: "start" }}>
            <h1 style={{ color: "#1976d2" }}>Nuevo ingreso</h1>
          </Grid>
          <Grid xs={4} style={{ textAlign: "end" }}>
            <Link to="/listado-ingresos">
              <Button variant="contained" color="error">
                Cancelar
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ height: "fit-content" }}>
          <Grid item xs={8}>
            <Autocomplete
              options={filteredProducts}
              getOptionLabel={(option) =>
                `SKU: ${option.sku_id}, Nombre: ${option.name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar productos"
                  variant="outlined"
                  onChange={(e) => setTextEntered(e.target.value)}
                />
              )}
              onChange={handleProductSelect}
              noOptionsText={isSearching ? "Buscando..." : "No hay resultados"}
            />
          </Grid>

          <Grid container item xs={4} justifyContent="end" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
              disabled={!canConfirm()}
            >
              Crear ingreso
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <CustomMaterialTable
            title="Productos agregados"
            data={selectedProducts}
            setData={(x) => setSelectedProducts(x)}
            columns={columns}
            style={{ width: "100%", marginTop: "20px" }}
            mensajeDataArrayVacio="No hay productos agregados"
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        title={"Confirmar el ingreso"}
      >
        <ModalIngreso
          amountEntered={amountEntered}
          selectedProducts={selectedProducts}
          handleRemoveProduct={handleRemoveProduct}
          cantidadIngresadaErrors={cantidadIngresadaErrors}
          setAlertObj={(x) => setAlertObj(x)}
          setOpen={(x) => setOpen(x)}
          setSelectedProducts={(x) => setSelectedProducts(x)}
          handleCloseModal={handleCloseModal}
          deposits={deposits}
          canConfirm={canConfirm}
        />
      </CustomModal>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </styles.RootContainer>
  );
}
