import React, { useEffect, useState } from "react";
import { Grid, Typography, Checkbox } from "@mui/material";

const ListadoDeIngresos = ({
  productos,
  withDeclared = false,
}) => {

  return (
    <Grid container item xs={12} style={{ marginTop: "20px" }}>
      <Grid container item xs={12} style={{ marginBottom: "10px" }}>
        <Grid item xs={2}>
          <Typography><b>SKU</b></Typography>
        </Grid>
        <Grid item xs={withDeclared ? 6 : 8}>
          <Typography><b>Descripción</b></Typography>
        </Grid>
        <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
          <Typography><b>Cantidad <br /> declarada</b></Typography>
        </Grid>
        {withDeclared && (
          <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
            <Typography><b>Cantidad <br /> ingresada</b></Typography>
          </Grid>
        )}
      </Grid>

      {productos.map((element) => (
        <Grid key={element.sku_id} container item xs={12} style={{ marginBottom: "10px" }}>
          <Grid item xs={2} style={{ display: "flex", justifyContent: "start" }}>
            <Typography>{element.sku_id}</Typography>
          </Grid>
          <Grid item xs={withDeclared ? 6 : 8} style={{ display: "flex", justifyContent: "start" }}>
            <Typography>{element.description || element.name}</Typography>
          </Grid>
          <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
            <Typography>{element.original_count || element.amountEntered}</Typography>
          </Grid>
          {withDeclared && (
            <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
              <Typography>{element.effective_count}</Typography>
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ListadoDeIngresos;
