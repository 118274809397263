import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ingressService from "../../../services/ingressService";
import productsService from "../../../services/productsService";
import CircularProgress from "@mui/material/CircularProgress";

const initialAlert = {
  severity: "success",
  message: "",
};

const CrearProductoCombo = ({ handleCreateProducto, productSKU, onClose }) => {
  const [sku, setSku] = useState("");
  const [nombre, setNombre] = useState("");
  const [productos, setProductos] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedProducto, setSelectedProducto] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [textEntered, setTextEntered] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const user_id = sessionStorage.getItem("User");
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    if (productSKU) {
      setLoadingSpinner(true);
      const fetchProductCombo = async () => {
        try {
          const res = await productsService.getProductsBySku(productSKU);
          const productData = Array.isArray(res.data) ? res.data[0] : res.data;
          setSku(productData.sku_id);
          setNombre(productData.name);
          const productDetails = await Promise.all(
            productData.combo.map(async (comboItem) => {
              const productRes = await productsService.getProductsBySku(
                comboItem.product_id
              );
              return {
                id: comboItem.product_id,
                cantidad: comboItem.quantity,
                name: productRes.data[0].name,
              };
            })
          );

          setProductos(productDetails);
        } catch (error) {
          console.error("Error al cargar los datos del combo:", error);
        } finally {
          setLoadingSpinner(false);
        }
      };

      fetchProductCombo();
    }
  }, [productSKU]);

  const clearForm = () => {
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  useEffect(() => {
    if (textEntered.length >= 3) {
      filterProductsByText(textEntered);
    } else {
      setFilteredProducts([]);
    }
  }, [textEntered]);

  const filterProductsByText = async (text) => {
    setIsSearching(true);
    try {
      const lowercasedText = text.toLowerCase();
      const res = await ingressService.getInventory();
      const products = res?.data?.results || [];
      const filtered = products.filter(
        (product) =>
          product.name.toLowerCase().includes(lowercasedText) ||
          product.sku_id.toLowerCase().includes(lowercasedText)
      );
      setFilteredProducts(filtered);
    } catch (error) {
      console.error("Error al filtrar productos:", error);
      setFilteredProducts([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleAddProducto = () => {
    if (selectedProducto) {
      const exists = productos.find((p) => p.id === selectedProducto.id);
      if (!exists) {
        setProductos([...productos, { ...selectedProducto, cantidad: 1 }]);
        setSelectedProducto(null);
        setSearch("");
        setFilteredProducts([]);
      } else {
        alert("El producto ya está en la lista.");
      }
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProductos = [...productos];
    updatedProductos.splice(index, 1);
    setProductos(updatedProductos);
  };

  const handleCrear = async () => {
    setLoadingButton(true)
    const productoCombo = {
      sku_id: sku,
      ean_id: `EAN${sku}`,
      name: nombre,
      height: 1,
      deep: 1,
      width: 1,
      weight: 1,
      observation: "observation combo test",
      user_id: Number(user_id),
      is_combo: true,
      active: true,
      combo: productos.map((producto) => ({
        product_id: producto.id,
        quantity: Number(producto.cantidad),
      })),
    };

    try {
      if (productSKU) {
        productoCombo.id = productSKU;

        await productsService.updateCombo(productoCombo);
        setAlertObj({
          severity: "success",
          message: "Combo editado exitosamente.",
        });
        handleCreateProducto();
      } else {
        await productsService.postProductCombo(productoCombo);
        setAlertObj({
          severity: "success",
          message: "Combo creado exitosamente.",
        });
      }
    } catch (error) {
      console.error("Error al guardar combo:", error);
      setAlertObj({
        severity: "error",
        message: "Hubo un error al intentar.",
      });
    } finally {
      setSnackbarOpen(true);
      setTimeout(() => {
        onClose();
      }, 2000);
      setLoadingButton(false)
    }
  };

  const isCrearDisabled = !sku || !nombre || productos.length === 0;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      {loadingSpinner && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <CircularProgress size={60} />
        </div>
      )}
      <Grid
        container
        spacing={1}
        sx={{
          overflowY: "auto",
          maxHeight: { xs: "80vh", sm: "80vh" },
          width: "100%", // Asegurar ancho completo
          margin: 0, // Eliminar márgenes automáticos
          padding: 2, // Añadir padding interno
        }}
      >
        <Grid container spacing={2} sx={{ marginBottom: 2, marginTop: 1 }}>
          <Grid item xs={6}>
            <TextField
              label="SKU *"
              fullWidth
              value={sku}
              onChange={(e) => {
                const inputValue = e.target.value.toUpperCase();
                const isValid = /^[A-Z0-9]*$/.test(inputValue);
                if (isValid && inputValue.length <= 14) {
                  setSku(inputValue);
                }
              }}
              disabled={!!productSKU}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Nombre del combo *"
              fullWidth
              value={nombre}
              onChange={(e) => setNombre(e.target.value.toUpperCase())}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={8}>
            <Autocomplete
              options={filteredProducts}
              getOptionLabel={(option) =>
                `SKU: ${option.sku_id}, Nombre: ${option.name}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buscar productos"
                  variant="outlined"
                  onChange={(e) => setTextEntered(e.target.value)}
                />
              )}
              noOptionsText={isSearching ? "Buscando..." : "No hay resultados"}
              onChange={(event, newValue) => setSelectedProducto(newValue)}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProducto}
              fullWidth
              disabled={!selectedProducto}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>

        {productos?.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={500}>Nombre</TableCell>
                  <TableCell width={50} align="center">
                    Cantidad
                  </TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productos.map((producto, index) => (
                  <TableRow key={index}>
                    <TableCell>{producto.name}</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        value={producto.cantidad}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            const updatedProductos = [...productos];
                            updatedProductos[index].cantidad = value;
                            setProductos(updatedProductos);
                          }
                        }}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveProduct(index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <p style={{ textAlign: "center", marginTop: "50px" }}>
                No hay productos agregados.
              </p>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          sx={{ marginTop: 2 }}
        >
          <Grid item>
            <Button onClick={clearForm} color="error"  variant="outlined">
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleCrear}
              color="primary"
              disabled={isCrearDisabled}
              variant="contained"
            >
              {productSKU ? (loadingButton ? "Cargando..." : "Guardar Cambios")  : (loadingButton ? "Cargando..." : "Crear")}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={alertObj.severity}
            sx={{ width: "100%" }}
          >
            {alertObj.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default CrearProductoCombo;
