import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import egressService from "../../../services/egressService";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import { useNavigate } from "react-router-dom";

const initialState = {
  type: "out",
  status: "pending",
  sub_movement_id: 0,
  receipt_id: "",
  provider_name: "",
  warehouse_id: 0,
  phone_number: "",
  email: "test@test.com",
  observation: "",
};

const ModalEgress = ({
  amountEntered,
  selectedProducts,
  handleRemoveProduct,
  cantidadIngresadaErrors,
  canConfirm,
  setAlertObj,
  setOpen,
  handleCloseModal,
  setSelectedProducts,
  deposits,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === "receipt_id" ? value.toUpperCase() : value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (deposits.length === 1) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        warehouse_id: deposits[0].id,
      }));
    }
  }, [deposits]);

  const handleConfirm = async () => {
    const requiredFields = ["comprobante", "contacto", "deposito", "telefono"];
    const newFormErrors = {};
    let hasErrors = false;

    if (hasErrors) {
      setFormErrors(newFormErrors);
      return;
    }

    const hasCantidadIngresadaErrors = Object.values(
      cantidadIngresadaErrors
    ).some((error) => error !== "");

    if (hasCantidadIngresadaErrors) {
      setFormErrors({ amountEntered: "Campos requeridos" });
      return;
    }

    setLoading(true);

    if (canConfirm()) {
      const requestBody = {
        ...formData,
        movement_details: selectedProducts.map((product) => ({
          product_id: product.id,
          description: product.observation,
          original_count: product.amountEntered,
          effective_count: 0,
        })),
      };

      try {
        let dataEgress = await egressService.PostCreateEgress(requestBody);
        if (dataEgress?.data) {
          setAlertObj({
            severity: "success",
            message: "Egreso creado exitosamente",
          });
          //
          setOpen(true);
        } else if (dataEgress?.data?.Error) {
          setAlertObj({
            severity: "error",
            message: "Ocurrio un error en el egreso",
          });
          setOpen(true);
        }
        setTimeout(() => {
          handleCloseModal();
          navigate("/listado-egresos");
        }, 2000);
      } catch (error) {
        const errorMessage = error.response?.data || error.message;

        setAlertObj({
          severity: "error",
          message: errorMessage.includes("error updating stock")
            ? "Stock insuficiente para realizar esta operación"
            : "Ocurrió un error en la creación.",
        });
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Comprobante asociado *"
              name="receipt_id"
              value={formData.receipt_id}
              onChange={handleChange}
              error={!!formErrors.receipt_id}
              helperText={formErrors.receipt_id}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Cliente *"
              name="provider_name"
              value={formData.provider_name}
              onChange={handleChange}
              error={!!formErrors.provider_name}
              helperText={formErrors.provider_name}
            />
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
              <FormControl fullWidth>
                {deposits.length === 1 ? (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "8px",
                      fontSize: "16px",
                    }}
                  >
                    {deposits[0].name.toUpperCase()}
                  </span>
                ) : (
                  <>
                    <InputLabel id="select-label">
                      {!!formErrors.warehouse_id
                        ? formErrors.warehouse_id
                        : "Almacén *"}
                    </InputLabel>
                    <Select
                      labelId="select-label"
                      id="select"
                      value={formData.warehouse_id}
                      label="Almacén"
                      name="warehouse_id"
                      onChange={handleChange}
                      error={!!formErrors.warehouse_id}
                      helperText={formErrors.warehouse_id}
                    >
                      <MenuItem value="0">Seleccione un almacén</MenuItem>
                      {deposits.map((element) => (
                        <MenuItem key={element.id} value={element.id}>
                          {element.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              label="Telefono *"
              name="phone_number"
              type="text"
              value={formData.phone_number}
              onChange={handleChange}
              error={!!formErrors.phone_number}
              helperText={formErrors.phone_number}
              inputProps={{
                pattern: "[0-9]*", // Solo permite números
                title: "Ingrese solo números",
              }}
            />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Observaciones"
            name="observation"
            value={formData.observation}
            onChange={handleChange}
            error={!!formErrors.observation}
            helperText={formErrors.observation}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <Typography>
            Total de unidades a egresar: <b>{amountEntered}</b>
          </Typography>
          <br></br>
          <Typography>
            Total SKUS: <b>{selectedProducts.length}</b>
          </Typography>
        </Grid>
        <ListadoDeProductos
          productos={selectedProducts}
          handleRemoveProduct={handleRemoveProduct}
        />
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={handleCloseModal}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {loading ? "Cargando..." : "Confirmar egreso"}
        </Button>
      </Grid>
    </>
  );
};

export default ModalEgress;
