import client from "../client/client";

const postProduct = async (data) => {
  try {
    let resp = await client.post("product-api/save", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const postProductMultiPack = async (data) => {
  try {
    let resp = await client.post("product-api/multipack/save", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const updateProductMultiPack = async (data) => {
  try {
    let resp = await client.put("product-api/multipack/update", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};
const postProductCombo = async (data) => {
  try {
    let resp = await client.post("product-api/combo/save", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const postProductMassive = async (data) => {
  try {
    let resp = await client.post("product-api/bulk", data);
    return resp;
  } catch (error) {
    console.error("Error al cargar los productos:", error);
    throw error;
  }
};

const updateCombo = async (data) => {
  try {
    let resp = await client.put("product-api/combo/update", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};


const putProducts = async (data) => {  
  try {
    let resp = await client.put(`product-api/update`, data);
    return resp;
  } catch (error) {
    console.error("Error al actualizar productos:", error);
    throw error;
  }
};


const getProductsBySku = async (productoSKU) => {
  try {
    const resp = await client.get(`product-api/product/${productoSKU}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};

const deleteProduct = async (productoSKU) => {
  try {
    const resp = await client.delete(`product-api/delete/${productoSKU}`);
    return resp;
  } catch (error) {
    console.error("Error al eliminar el producto", error);
    throw error;
  }
};

const enableProduct = async (productoSKU) => {
  try {
    const resp = await client.delete(`product-api/active/${productoSKU}`);
    return resp;
  } catch (error) {
    console.error("Error al eliminar el producto", error);
    throw error;
  }
};


const getHistory = async (data) => {
  const user_id = sessionStorage.getItem('User');
  const requestData = {
    product_id: data, 
    user_id: parseInt(user_id, 10),
  };

  try {
    const resp = await client.get(`warehouse-api/movement/product/search`, {
      params: requestData,
    });
    return resp;
  } catch (error) {
    console.error("Error al obtener historial:", error.response?.data || error);
    throw error;
  }
};


let productsServices = {
  postProduct,
  putProducts,
  getProductsBySku,
  getHistory,
  postProductMultiPack,
  updateProductMultiPack,
  postProductCombo,
  updateCombo,
  deleteProduct,
  enableProduct,
  postProductMassive
};

export default productsServices;
