import React from 'react';
import { Route, Routes } from "react-router-dom";
import AppBarComponent from "../components/AppBarComponenet";
import AltaProductos from "../layouts/productos";
import ListadoIngresos from "../layouts/ingress/listadoIngresos";
import CrearIngreso from "../layouts/ingress/createIngress";
import Usuarios from "../layouts/usuarios";
import Inventario from "../layouts/inventory";
import ListadoEgresos from "../layouts/egress";
import CreateEgress from '../layouts/egress/createEgress';
import Envios from "../layouts/integraciones/envios";
import { verifyRol } from "../helpers";
import NoPermitido from "../layouts/sinPermiso";
import Perfil from "../layouts/perfil";
import Home from "../layouts/operario/Home";
import Ingreso from "../layouts/operario/Ingreso";
import Detalle from "../layouts/operario/Detalle";
import DetalleRecibir from "../layouts/operario/DetalleRecibir";
import Almacen from "../layouts/almacen/Almacen";
import MoverInventario from "../layouts/inventory/mover/MoverInventario";

export const DashboardRoutes = () => {
  //let rol = sessionStorage.getItem("ROL");
  //DEPENDIENDO EL ROL VAMOS A DARLE ACCESO A LAS DIFERENTES RUTAS  !!!!!!!! NO OLVIDAR QUE ESTE CAMBIO TAMBIEN SE APLICA EN SideBar.js !!!!!!!!!!!!!//

  return (
    <>
      <AppBarComponent />
      <Routes>
        <Route path="/home" element={<p>home</p>} />
        <Route path="/alta-productos" element={<AltaProductos />} />
        <Route
          path="/listado-productos"
          element={<p>listado de productos</p>}
        />
        <Route path="/mover-inventario" element={<MoverInventario />} />
        <Route path="/crear-ingreso" element={<CrearIngreso />} />
        <Route path="/listado-ingresos" element={<ListadoIngresos />} />
        <Route
          path="/confirmar-ingreso"
          element={<p>formulario para confirmar ingresos</p>}
        />
        <Route path="/inventario" element={<Inventario />} />
        <Route path="/crear-egreso" element={<CreateEgress />} />
        <Route path="/listado-egresos" element={<ListadoEgresos />} />
        <Route path="/perfil" element={<Perfil />} />

        <Route path="/operario/" element={<Home />} />
        <Route path="/operario/ingreso" element={<Ingreso />} />
        <Route path="/operario/detalle" element={<Detalle />} />
        <Route path="/operario/recibir" element={<DetalleRecibir />} />

        <Route path="/almacen" element={<Almacen />} />

        <Route
          path="/usuarios"
          element={
            verifyRol(["ADMIN"]) ? <Usuarios /> : <NoPermitido />
          }
        ></Route>
        <Route
          path="/envios"
          element={
            verifyRol(["Super Usuario", "Administrativo"]) ? <Envios /> : <NoPermitido />
          }
        />
      </Routes>
    </>
  );
};
