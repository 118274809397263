import { useEffect, useState } from "react";
import { TextField, Grid, Button, Typography, Alert, Snackbar } from "@mui/material";
import productsService from "../../../services/productsService";
import CircularProgress from "@mui/material/CircularProgress";

const initialAlert = { severity: "success", message: "" };

const MultiBultoModal = ({ handleCreateProducto, productSKU, onClose }) => {
  const [cantidadBultos, setCantidadBultos] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [bultoPrincipal, setBultoPrincipal] = useState({});
  const [bultosData, setBultosData] = useState([]);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [openAlert, setOpenAlert] = useState(false);
  const user_id = sessionStorage.getItem("User");
  const [errorCantidadBultos, setErrorCantidadBultos] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    const fetchProductCombo = async () => {
      setLoadingSpinner(true);
      try {
        const res = await productsService.getProductsBySku(productSKU);
        const productData = Array.isArray(res.data) ? res.data : [res.data];

        if (!productData.length) return;

        const internalBultos = productData.filter((item) => !item.is_multipack);

        const principal = productData.find((item) => item.is_multipack) || {};

        setBultoPrincipal({
          id: principal.id || null,
          sku: principal.sku_id || "",
          ean: principal.ean_id || "",
          nombre: principal.name || "",
          alto: principal.height || 0,
          largo: principal.deep || 0,
          ancho: principal.width || 0,
          peso: principal.weight || 0,
          observaciones: principal.observation || "",
        });

        const formattedBultos = internalBultos.map((item) => ({
          id: item.id || null,
          sku: item.sku_id || "",
          ean: item.ean_id || "",
          nombre: item.name || "",
          alto: item.height || 0,
          largo: item.deep || 0,
          ancho: item.width || 0,
          peso: item.weight || 0,
          observaciones: item.observation || "",
        }));

        setBultosData(formattedBultos);
        setCantidadBultos(internalBultos.length || 2);

        setCurrentStep(0);
      } catch (error) {
        console.error("Error al cargar los datos del producto:", error);
      } finally {
        setLoadingSpinner(false);
      }
    };

    if (productSKU) {
      fetchProductCombo();
    }
  }, [productSKU]);

  useEffect(() => {
    if (bultoPrincipal?.ean && currentStep > 0) {
      setBultosData((prevData) => {
        const updatedData = [...prevData];
        updatedData[currentStep - 1] = {
          ...updatedData[currentStep - 1],
          ean: bultoPrincipal.ean,
        };
        return updatedData;
      });
    }
  }, [bultoPrincipal, currentStep]);

  const handlePrincipalChange = (field, value) => {
    setBultoPrincipal({ ...bultoPrincipal, [field]: value });
  };

  const handleBultoChange = (field, value) => {
    const newBultosData = [...bultosData];
    newBultosData[currentStep - 1] = {
      ...newBultosData[currentStep - 1],
      [field]: value,
    };
    setBultosData(newBultosData);
  };

  const handleNext = () => {
    if (currentStep === 0 && bultosData.length === 0) {
      setBultosData(
        Array.from({ length: cantidadBultos }, () => ({
          sku: "",
          ean: "",
          nombre: "",
          peso: "",
          ancho: "",
          largo: "",
          alto: "",
          observaciones: "",
        }))
      );
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSave = async () => {
    setLoadingButton(true);
    const bultosInternos = bultosData.map((bulto, index) => ({
      ...(productSKU && bulto.id ? { id: bulto.id } : {}),
      sku_id: bulto.sku,
      ean_id: bulto.ean,
      name: bulto.nombre || "",
      height: Number(bulto.alto || 0),
      deep: Number(bulto.largo || 0),
      width: Number(bulto.ancho || 0),
      weight: Number(bulto.peso || 0),
      observation: bulto.observaciones || "",
      user_id: Number(user_id),
      is_multipack: false,
      active: true,
    }));

    const bultoPrincipalObj = {
      ...(productSKU && bultoPrincipal.id ? { id: bultoPrincipal.id } : {}),
      sku_id: bultoPrincipal.sku,
      ean_id: bultoPrincipal.ean || "",
      name: bultoPrincipal.nombre || "",
      height: Number(bultoPrincipal.alto || 0),
      deep: Number(bultoPrincipal.largo || 0),
      width: Number(bultoPrincipal.ancho || 0),
      weight: Number(bultoPrincipal.peso || 0),
      observation: bultoPrincipal.observaciones || "",
      user_id: Number(user_id),
      is_multipack: true,
      active: true,
    };

    const allBultos = [bultoPrincipalObj, ...bultosInternos];

    try {
      if (productSKU) {
        let newProduct =
          await productsService.updateProductMultiPack(allBultos);
        if (newProduct?.data) {
          setAlertObj({
            severity: "success",
            message: "Producto editado exitosamente",
          });
          setOpenAlert(true);
          setTimeout(() => {
            onClose();
          }, 1000);
        }
      } else {
        let newProduct = await productsService.postProductMultiPack(allBultos);
        if (newProduct?.data) {
          setAlertObj({
            severity: "success",
            message: "Producto guardado exitosamente",
          });
          setOpenAlert(true);
          setTimeout(() => {
            onClose();
          }, 1000);
        }
      }
    } catch (error) {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error al guardar el producto",
      });
      setOpenAlert(true);
    } finally {
      setLoadingButton(false);
    }
  };

  function generateHash(length = 10) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let hash = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      hash += characters[randomIndex];
    }
    return hash;
  }

  const handleGenerateHash = () => {
    const newHash = generateHash(10);
    handleBultoChange("sku", newHash);
  };

  const isStepValid = () => {
    if (currentStep === 0) {
      return (
        cantidadBultos > 1 &&
        bultoPrincipal.sku?.trim() &&
        bultoPrincipal.ean?.trim() &&
        bultoPrincipal.nombre?.trim()
      );
    } else {
      const currentBulto = bultosData[currentStep - 1];
      return (
        currentBulto?.sku?.trim() &&
        currentBulto?.nombre?.trim() &&
        currentBulto?.peso > 0 &&
        currentBulto?.ancho > 0 &&
        currentBulto?.largo > 0 &&
        currentBulto?.alto > 0
      );
    }
  };
  const handleCantidadBultosChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10);

      if (numericValue < 2) {
        setErrorCantidadBultos("La cantidad mínima es 2");
      } else {
        setErrorCantidadBultos("");
      }

      setCantidadBultos(numericValue || "");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      {loadingSpinner && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <CircularProgress size={60} />
        </div>
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ padding: "0px" }}>
          <Typography variant="h6" align="center">
            {currentStep !== 0
              ? `Bulto ${currentStep}/${cantidadBultos}`
              : null}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {currentStep === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Cantidad de Bultos *"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(cantidadBultos),
                  }}
                  value={cantidadBultos || ""}
                  onChange={handleCantidadBultosChange}
                  error={!!errorCantidadBultos}
                  helperText={errorCantidadBultos}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="SKU *"
                  margin="normal"
                  fullWidth
                  value={bultoPrincipal.sku || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    if (inputValue.length > 14) return;
                    handlePrincipalChange("sku", inputValue);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="EAN *"
                  fullWidth
                  value={bultoPrincipal.ean || ""}
                  onChange={(e) => {
                    const regex = /^[a-zA-Z0-9]*$/;
                    const inputValue = e.target.value.toUpperCase();

                    if (inputValue.length > 14) return;
                    if (!regex.test(inputValue)) return;

                    handlePrincipalChange("ean", inputValue);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre *"
                  fullWidth
                  value={bultoPrincipal.nombre || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    handlePrincipalChange("nombre", inputValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observaciones"
                  fullWidth
                  multiline
                  rows={2}
                  value={bultoPrincipal.observaciones || ""}
                  onChange={(e) =>
                    handlePrincipalChange("observaciones", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          )}
          {currentStep > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Código *"
                  fullWidth
                  value={bultosData[currentStep - 1]?.sku || ""}
                  onChange={(e) => handleBultoChange("sku", e.target.value)}
                />
                <Typography
                  variant="body2"
                  color="primary"
                  style={{
                    marginTop: "2px",
                    fontSize: "12px",
                    marginLeft: "2px",
                    cursor: "pointer",
                  }}
                  onClick={handleGenerateHash}
                >
                  Recomendar código
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="EAN *"
                  fullWidth
                  value={bultoPrincipal?.ean}
                  disabled
                  onChange={(e) => handleBultoChange("ean", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Nombre *"
                  fullWidth
                  value={bultosData[currentStep - 1]?.nombre || ""}
                  onChange={(e) => handleBultoChange("nombre", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Peso en gramos *"
                  fullWidth
                  value={bultosData[currentStep - 1]?.peso || ""}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    handleBultoChange("peso", value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Ancho cm *"
                  fullWidth
                  value={bultosData[currentStep - 1]?.ancho || ""}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    handleBultoChange("ancho", value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Largo cm *"
                  fullWidth
                  value={bultosData[currentStep - 1]?.largo || ""}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    handleBultoChange("largo", value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Alto cm *"
                  fullWidth
                  value={bultosData[currentStep - 1]?.alto || ""}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    handleBultoChange("alto", value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observaciones"
                  fullWidth
                  multiline
                  rows={2}
                  value={bultosData[currentStep - 1]?.observaciones || ""}
                  onChange={(e) =>
                    handleBultoChange("observaciones", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} container justifyContent="end">
          {currentStep === 0 && (
            <Button onClick={onClose} color="error" variant="outlined">
              Cancelar
            </Button>
          )}
          {currentStep > 0 && (
            <Button onClick={handleBack} variant="outlined">
              Anterior
            </Button>
          )}
          {currentStep !== cantidadBultos && (
            <Button
              onClick={handleNext}
              variant="contained"
              disabled={!isStepValid()}
              style={{ marginLeft: "5px" }}
            >
              Siguiente
            </Button>
          )}
          {currentStep === cantidadBultos && (
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={!isStepValid()}
              style={{ marginLeft: "5px" }}
            >
              {loadingButton ? "Cargando..." : "Guardar"}
            </Button>
          )}
        </Grid>
      </Grid>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MultiBultoModal;
