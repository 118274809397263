import React, { useState, useEffect } from "react";
import TablaInventario from "./tablaInventario";
import CustomModal from "../../components/customModal";
import CrearProducto from "./productos/crearproducto";
import HistorialModal from "./historial/HistorialModal";
import { Grid, TextField, Button, Menu, MenuItem } from "@mui/material";
import MultiBultoModal from "./productos/productMultiPack";
import CrearProductoCombo from "./productos/combo";
import ingressService from "../../services/ingressService";
import LoadMasive from "./productos/loadMasive";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterModalInventory from "../../components/MFilters/filterInventory";

const Inventario = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenCombo, setModalOpenCombo] = useState(false);
  const [openModalMultiPack, setOpenModalMultiPack] = useState(false);

  const [modalHistorialOpen, setModalHistorialOpen] = useState(false);
  const [productoCreado, setProductoCreado] = useState(0);
  const [productoSKU, setProductoSKU] = useState("");
  const [filterText, setFilterText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [openLoadMasiveModal, setOpenLoadMasiveModal] = useState(false);

  const [products, setProducts] = useState([]);
  const [searchInventory, setSearchInventory] = useState(false);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [activeFilters, setActiveFilters] = useState({
    active: "",
    // productType: "all",
  });

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleApplyFilters = (filters) => {
    setActiveFilters(filters);
    inventory(filters);
  };

  const inventory = async (
    page = 1,
    pageSize = 10,
    filters = activeFilters
  ) => {
    try {
      setSearchInventory(true);
      let result = await ingressService.getInventory(
        filterText,
        filters.active,
        page,
        pageSize
        // filters.productType
      );
      if (result?.data) {
        setProducts(result?.data);
      } else {
        setProducts([]);
      }
      setSearchInventory(false);
    } catch (error) {
      console.error("Hubo un error:", error);
      setSearchInventory(false);
    }
  };

  const handleCreateProducto = (page = 1, pageSize = 10) => {
    inventory(page, pageSize);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length >= 3 || value.length === 0) {
      setFilterText(value);
    }
  };

  useEffect(() => {
    inventory();
  }, [productoCreado, filterText]);

  function handleOpenModalEdit(product) {
    setProductoSKU(product.id);
    if (product.is_combo) {
      setModalOpenCombo(true);
    } else if (product.is_multipack) {
      setOpenModalMultiPack(true);
    } else if (!product.is_combo && !product.is_multipack) {
      setModalOpen(true);
    }
  }

  function handleOpenModalHistorial(sku) {
    setProductoSKU(sku.id);
    setModalHistorialOpen(true);
  }

  const hadnleCloseModalEdit = () => {
    setProductoSKU("");
    setModalOpen(false);
  };

  const hadnleCloseModalEditCombo = () => {
    setProductoSKU("");
    setModalOpenCombo(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (option === "Producto convencional") {
      handleOpenModal();
    } else if (option === "Combo") {
      handleOpenModalCombo();
    } else if (option === "Multibulto") {
      handleOpenModalMultiPack();
    }
    handleMenuClose();
  };

  const handleOpenModal = () => {
    setProductoSKU("");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalCombo = () => {
    setProductoSKU("");
    setModalOpenCombo(true);
  };

  const handleCloseModalCombo = () => {
    setModalOpenCombo(false);
  };

  const handleOpenModalMultiPack = () => {
    setProductoSKU("");
    setOpenModalMultiPack(true);
  };

  const handleCloseModalMultiPack = () => {
    setOpenModalMultiPack(false);
  };

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid
          container
          item
          xs={12}
          sm={11}
          sx={{
            marginTop: "5vh",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={8} style={{ textAlign: "start" }}>
              <h1 style={{ color: "#1976d2", margin: "1px" }}>Inventario</h1>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              size="small"
              id="outlined-basic"
              label="Buscar por SKU, EAN y NOMBRE"
              variant="outlined"
              style={{ flexGrow: 1 }}
              onChange={handleFilterChange}
            />

            <Button
              style={{
                marginLeft: "10px",
                width: "50px",
                height: "40px",
                minWidth: "50px",
              }}
              variant="contained"
              color="primary"
              onClick={handleFilterClick}
            >
              <FilterAltIcon />
            </Button>
            <FilterModalInventory
              anchorEl={filterAnchorEl}
              open={Boolean(filterAnchorEl)}
              onClose={handleFilterClose}
              onApplyFilters={handleApplyFilters}
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              sx={{ width: "200px", marginRight: "5px" }}
              onClick={() => setOpenLoadMasiveModal(true)}
            >
              Carga masiva
            </Button>
            <Button
              variant="contained"
              onClick={handleMenuOpen}
              sx={{ width: "200px" }}
            >
              Nuevo producto
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              slotProps={{
                paper: {
                  sx: {
                    width: anchorEl ? anchorEl.offsetWidth : undefined,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => handleOptionClick("Producto convencional")}
              >
                Producto convencional
              </MenuItem>
              <MenuItem onClick={() => handleOptionClick("Combo")}>
                Combo
              </MenuItem>
              <MenuItem onClick={() => handleOptionClick("Multibulto")}>
                Multibulto
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={11}>
          <TablaInventario
            filterText={filterText}
            handleCreateProducto={handleCreateProducto}
            handleOpenModalEdit={handleOpenModalEdit}
            handleOpenModalHistorial={handleOpenModalHistorial}
            searchInventory={searchInventory}
            products={products}
          />
        </Grid>
      </Grid>

      <HistorialModal
        open={modalHistorialOpen}
        setOpen={setModalHistorialOpen}
        productoSKU={productoSKU}
      />

      <CustomModal
        open={modalOpen}
        onClose={productoSKU ? hadnleCloseModalEdit : handleCloseModal}
        title={productoSKU ? "Editar Producto" : "Crear Producto"}
        sx={{
          width: { xs: "90%", sm: "50%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <CrearProducto
          handleCreateProducto={handleCreateProducto}
          productoSKU={productoSKU}
          handleCloseModal={handleCloseModal}
        />
      </CustomModal>

      <CustomModal
        open={modalOpenCombo}
        onClose={
          productoSKU ? hadnleCloseModalEditCombo : handleCloseModalCombo
        }
        title={productoSKU ? "Editar Combo" : "Crear Combo"}
        sx={{
          width: { xs: "90%", sm: "50%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <CrearProductoCombo
          handleCreateProducto={handleCreateProducto}
          productSKU={productoSKU}
          onClose={handleCloseModalCombo}
        />
      </CustomModal>

      <CustomModal
        open={openModalMultiPack}
        onClose={handleCloseModalMultiPack}
        title={productoSKU ? "Editar Multibulto" : "Crear Multibulto"}
        sx={{
          width: { xs: "90%", sm: "50%", md: "50%" },
          mx: "auto",
          mt: "5vh",
        }}
      >
        <MultiBultoModal
          handleCreateProducto={handleCreateProducto}
          productSKU={productoSKU}
          onClose={handleCloseModalMultiPack}
        />
      </CustomModal>
      <LoadMasive
        open={openLoadMasiveModal}
        onClose={() => setOpenLoadMasiveModal(false)}
      />
    </>
  );
};

export default Inventario;
