import {
  Grid,
  Tooltip,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Backdrop,
} from "@mui/material";
import { TextField } from "@mui/material";
import CustomMaterialTable from "../../components/MaterialTable";
import { useEffect, useState } from "react";
import egressService from "../../services/egressService";
import * as styles from "./styles";
import { formatDateString } from "../../helpers";
import { Link, useNavigate } from "react-router-dom";
import { returnRol } from "../../utils/constants";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterModal from "../../components/MFilters/index.js";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import ingressService from "../../services/ingressService.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ModalAccepted from "./modals/modalAccepted.js";
import ModalPrepared from "./modals/modalPrepared.js";
import ModalView from "../egress/modals/modalView.js";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ModalDispatched from "../egress/modals/modalDispatched.js";
import CircularProgress from "@mui/material/CircularProgress";
import LogoPdf from "../../../src/assets/LogoPdf.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const initialAlert = {
  severity: "success",
  message: "",
};

const ListadoEgresos = () => {
  const [listadoDeEgresos, setListadoDeEgresos] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [buscandoEgresos, setBuscandoEgresos] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [initialState, setInitialState] = useState("pending");
  const currentDate = new Date();
  const oneMonthBefore = new Date();
  const [ingressDetail, setIngressDetail] = useState({});
  const [movementDetails, setMovementDetails] = useState({});
  const [modalPending, setModalPending] = useState(false);
  const [modalDone, setModalDone] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalDispatched, setModalDispatched] = useState(false);
  const [selectedEgressId, setSelectedEgressId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  oneMonthBefore.setMonth(currentDate.getMonth() - 6);

  const [filters, setFilters] = useState({
    from: formatDate(oneMonthBefore),
    to: formatDate(currentDate),
    type: "out",
  });

  const [paginacion, setPaginacion] = useState({
    page: 1,
    pageSize: 5,
    total: 0,
  });

  const statusOptions = {
    pending: "Pendiente",
    in_progress: "En proceso",
    prepared: "Preparado", //aca resta el stock
    done: "Despachado",
    cancelled: "Cancelado",
  };

  const fetchAllEgresos = async (page, pageSize) => {
    try {
      const result = await egressService.getAllEgress({
        ...filters,
        page,
        pageSize,
      });

      const { results } = result.data;

      const sortedResults = results.sort(
        (a, b) => new Date(b.date_created) - new Date(a.date_created)
      );

      setListadoDeEgresos(sortedResults);
      setBuscandoEgresos(true);
    } catch (error) {
      setListadoDeEgresos(false);
    }
  };

  useEffect(() => {
    fetchAllEgresos(paginacion.page, paginacion.pageSize);
  }, [filters]);

  //Modal View
  const handleOpenDetailModal = async (id, isConfirm) => {
    setOpenBackdrop(true);

    const result = await ingressService.getIngressById(id);

    setIngressDetail(result.data);
    setOpenBackdrop(false);

    setModalView(true);
  };

  const handleCloseModalView = () => {
    setModalView(false);
  };

  //Modal preparing
  const handleOpenDialog = (egress) => {
    setIngressDetail(egress);
    setModalPending(true);
  };
  const handleCloseDialog = () => {
    setModalPending(false);
  };

  //Modal Done
  const handleOpenModalDone = (egress) => {
    setMovementDetails(egress.movement_details);
    setIngressDetail(egress);
    setModalDone(true);
  };

  const handleCloseDone = () => {
    setModalDone(false);
  };

  //Modal Dispached
  const handleOpenDispatched = (egress) => {
    setIngressDetail(egress);
    setModalDispatched(true);
  };

  const handleCloseDispatched = () => {
    setModalDispatched(false);
  };

  const handleConfirm = async (status) => {
    setLoading(true);
    const ingressData = {
      id: ingressDetail.id,
      type: ingressDetail.type,
      status: status,
      sub_movement_id: ingressDetail.sub_movement_id,
      receipt_id: ingressDetail.receipt_id,
      provider_name: ingressDetail.provider_name,
      warehouse_id: ingressDetail.warehouse_id,
      email: ingressDetail.email,
      observation: ingressDetail.observation,
      phone_number: ingressDetail.phone_number,
      user_id: ingressDetail.user_id,
      movement_details: ingressDetail.movement_details.map((detalle) => ({
        product_id: detalle.product_id,
        description: detalle.description,
        original_count: detalle.original_count,
        effective_count:
          status === "prepared"
            ? detalle.original_count
            : detalle.effective_count,
      })),
    };

    try {
      await ingressService.postRegisterIngress(ingressData);
      const successMessage =
        status === "in_progress"
          ? "Pedido aceptado exitosamente."
          : status === "prepared"
            ? "Pedido preparado exitosamente."
            : status === "done"
              ? "Pedido despachado exitosamente."
              : "Operación realizada exitosamente.";

      setAlertObj({
        severity: "success",
        message: successMessage,
      });
      setOpenSnack(true);
      if (status === "in_progress") {
        handleCloseDialog();
        generatePDF();
      } else if (status === "prepared") {
        handleCloseDone();
      }
      handleCloseDispatched();
      fetchAllEgresos(paginacion.page, paginacion.pageSize);
      setLoading(false);
    } catch (error) {
      setAlertObj({
        severity: "error",
        message: "Error al realizar la operación.",
      });
      setOpenSnack(true);
      setLoading(false);
      if (status === "in_progress") {
        handleCloseDialog();
      } else if (status === "prepared") {
        handleCloseDone();
      }
      handleCloseDispatched();
    }
  };

  const generatePDF = async () => {
    try {
      const doc = new jsPDF();
      const currentDate = new Date().toLocaleDateString();

      const optimizedLogo = await optimizeImage(LogoPdf, {
        maxWidth: 300,
        maxHeight: 240,
        quality: 0.8,
      });

      doc.setFont("Helvetica");

      const logoWidth = 25;
      const logoHeight = 20;
      doc.addImage(
        optimizedLogo,
        "PNG",
        170,
        10,
        logoWidth,
        logoHeight,
        undefined,
        "FAST"
      );

      doc.setFontSize(20);
      doc.setFont("Helvetica", "bold");
      doc.text("DETALLE DE EGRESO", 105, 20, { align: "center" });

      let verticalSpace = 55;

      doc.setFontSize(12);
      doc.setFont("Helvetica", "normal");
      doc.text("Empresa Voras", 105, 30, { align: "center" });
      doc.text("Calle 123, Ciudad de Córdoba", 105, 35, { align: "center" });
      doc.text(
        "Teléfono: (123) 456-7890 | Email: contacto@empresa.com",
        105,
        40,
        { align: "center" }
      );

      doc.line(15, 45, 195, 45);

      doc.setFontSize(10);
      doc.setFont("Helvetica", "bold");
      doc.text("Información del egreso", 20, verticalSpace);

      doc.setFont("Helvetica", "normal");
      verticalSpace += 8;

      doc.text(`Fecha de Creación: ${currentDate}`, 20, verticalSpace);
      doc.text(`Id Orden: ${ingressDetail.id}`, 110, verticalSpace);

      verticalSpace += 5;
      doc.text(`Cliente: ${ingressDetail.provider_name}`, 20, verticalSpace);
      doc.text(`Teléfono: ${ingressDetail.phone_number}`, 110, verticalSpace);

      verticalSpace += 5;
      doc.text(`Comprobante: ${ingressDetail.receipt_id}`, 20, verticalSpace);
      doc.text(`Depósito: ${ingressDetail.warehouse_id}`, 110, verticalSpace);

      verticalSpace += 5;
      doc.text(
        `Observaciones: ${ingressDetail.observation}`,
        20,
        verticalSpace
      );
      doc.text(`Email: ${ingressDetail.email}`, 110, verticalSpace);

      verticalSpace += 10;
      doc.line(15, verticalSpace, 195, verticalSpace);

      verticalSpace += 10;
      const data = ingressDetail?.movement_details?.map((product) => [
        product.sku_id,
        product.description,
        product.original_count,
      ]);

      autoTable(doc, {
        startY: verticalSpace,
        head: [["SKU", "Descripción", "Cantidad"]],
        body: data,
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 115 },
          2: { cellWidth: 25 },
        },
        styles: {
          overflow: "linebreak",
          halign: "center",
          valign: "middle",
          fontSize: 10,
          cellPadding: 3,
        }
      });

      verticalSpace = doc.lastAutoTable.finalY + 10;
      const totalUnidadesIngresadas = ingressDetail?.movement_details?.reduce(
        (total, producto) => total + producto.original_count,
        0
      );

      doc.setFont("Helvetica", "bold");
      doc.text(
        `Total de productos: ${ingressDetail.movement_details.length}`,
        20,
        verticalSpace
      );
      verticalSpace += 5;
      doc.text(
        `Total de unidades: ${totalUnidadesIngresadas}`,
        20,
        verticalSpace
      );

      doc.save(`Detalle_Egreso_${ingressDetail.id}.pdf`);
    } catch (error) {
      console.error("Error generando el PDF:", error);
      setAlertObj({
        severity: "error",
        message: "Error al generar el PDF",
      });
      setOpenSnack(true);
    }
  };

  const optimizeImage = (imageSource, options = {}) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");

        let width = img.width;
        let height = img.height;

        if (options.maxWidth && width > options.maxWidth) {
          height = (options.maxWidth * height) / width;
          width = options.maxWidth;
        }

        if (options.maxHeight && height > options.maxHeight) {
          width = (options.maxHeight * width) / height;
          height = options.maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL("image/png", options.quality || 0.8));
      };
      img.src = imageSource;
    });
  };

  const columns = [
    {
      title: "Fecha de Creacion",
      field: "date_created",
      minWidth: 150,
      render: (rowData) => formatDateString(rowData["date_created"]),
    },
    {
      title: "Cliente",
      field: "provider_name",
      maxWidth: 100,
    },
    {
      title: "Comprobante",
      field: "receipt_id",
      maxWidth: 100,
    },
    {
      title: "Estado",
      field: "status",
      maxWidth: 100,
      render: (rowData) => (
        <div
          style={{
            backgroundColor:
              rowData.status === "done" ? "lightgreen" : "transparent",
            borderRadius: "4px",
            padding: "4px 8px",
            textAlign: "center",
          }}
        >
          {statusOptions[rowData.status]}
        </div>
      ),
    },
    {
      title: "Acciones",
      field: "acciones",
      render: (rowData) => (
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {rowData.status === "pending" && (
            <Tooltip title="Preparar pedido">
              <ReceiptLongIcon
                variant="outlined"
                onClick={() => handleOpenDialog(rowData)}
                style={{ cursor: "pointer", color: "primary" }}
              />
            </Tooltip>
          )}
          {rowData.status === "in_progress" && (
            <Tooltip title="Marcar como preparado">
              <ContentPasteGoIcon
                variant="outlined"
                onClick={() => handleOpenModalDone(rowData)}
                style={{ cursor: "pointer", color: "primary" }}
              />
            </Tooltip>
          )}
          {rowData.status === "prepared" && (
            <Tooltip title="Marcar como despachado">
              <LocalShippingIcon
                variant="outlined"
                onClick={() => handleOpenDispatched(rowData)}
                style={{ cursor: "pointer", color: "primary" }}
              />
            </Tooltip>
          )}
          <Tooltip title="Detalle de egreso">
            <VisibilityIcon
              variant="outlined"
              onClick={() => handleOpenDetailModal(rowData.id, false)}
              style={{ cursor: "pointer", color: "primary" }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleOpenFilterModal = (event) => setAnchorEl(event.currentTarget);
  const handleCloseFilterModal = () => setAnchorEl(null);
  const handleFilterApply = (newFilters) => setFilters(newFilters);

  const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue);
    setFilters({ ...filters, status: newValue });
  };

  return (
    <>
      <Grid container component={styles.tableContainer} padding={3}>
        <Grid container item xs={6} component={styles.filtersContainer}>
          <styles.StyledAutocomplete
            options={Object.keys(statusOptions)}
            getOptionLabel={(option) => statusOptions[option]}
            renderInput={(params) => <TextField {...params} label="Estado" />}
            value={selectedStatus}
            onChange={handleStatusChange}
          />
          <Button
            style={{
              marginLeft: "15px",
              width: "50px",
              height: "40px",
              minWidth: "50px",
            }}
            variant="contained"
            color="primary"
            onClick={handleOpenFilterModal}
          >
            <FilterAltIcon />
          </Button>
          {/* <Button
          style={{
            marginLeft: "10px",
            width: "50px", 
            height: "40px",
            minWidth: "50px",
          }}
          variant="contained"
          color="primary"
        >
          <ClearIcon />
        </Button> */}

          <FilterModal
            anchorEl={anchorEl}
            onClose={handleCloseFilterModal}
            onFilter={handleFilterApply}
            initialFilters={filters}
          />
        </Grid>
        <Grid container item xs={6} component={styles.filtersContainerEnd}>
          <Link to="/crear-egreso">
            <Button variant="contained" color="primary">
              Crear egreso
            </Button>
          </Link>
        </Grid>
        <Grid container item xs={12}>
          <CustomMaterialTable
            title="Listado de Egresos"
            data={listadoDeEgresos}
            columns={columns}
            style={{ width: "100%", marginTop: "20px" }}
            mensajeDataArrayVacio={
              buscandoEgresos
                ? "Buscando egresos"
                : "No hay egresos disponibles"
            }
          />
        </Grid>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={alertObj?.severity}
            sx={{ width: "100%" }}
          >
            {alertObj.message}
          </Alert>
        </Snackbar>
        <ModalAccepted
          open={modalPending}
          loading={loading}
          onClose={handleCloseDialog}
          onConfirm={() => {
            handleConfirm("in_progress");
          }}
        />
        <ModalDispatched
          loading={loading}
          open={modalDispatched}
          onClose={handleCloseDispatched}
          onConfirm={() => {
            handleConfirm("done");
          }}
        />
        <ModalPrepared
          ingressDetail={movementDetails}
          loading={loading}
          open={modalDone}
          onClose={handleCloseDone}
          onConfirm={() => {
            handleConfirm("prepared");
          }}
        />
        <ModalView
          ingressDetail={ingressDetail}
          open={modalView}
          onClose={handleCloseModalView}
        />
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ListadoEgresos;
