import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const ModalPrepared = ({
  ingressDetail,
  open,
  onClose,
  onConfirm,
  loading,
}) => {
  const [scannedData, setScannedData] = useState("");
  const [lastScannedItem, setLastScannedItem] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [error, setError] = useState("");
  const [cantidadError, setCantidadError] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [productScanCounts, setProductScanCounts] = useState({});
  const [isQuantityFocused, setIsQuantityFocused] = useState(false);

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setLastScannedItem(null);
    setScannedData("");
    setSelectedProducts([]);
    setError("");
    setCantidadError("");
    setCantidad("");
    setProductScanCounts({});
  };

  const resetCurrentScan = () => {
    setLastScannedItem(null);
    setScannedData("");
    setCantidad("");
    setError("");
    setCantidadError("");
  };

  const validateQuantity = (value, product) => {
    if (!product) return true;
    
    const currentCount = productScanCounts[product.sku_id] || 0;
    const remainingCount = product.original_count - currentCount;
    const numValue = parseInt(value);
    
    if (numValue > remainingCount) {
      setCantidadError(`Cantidad máxima ${remainingCount}`);
      return false;
    }
    
    setCantidadError("");
    return true;
  };

  const updateProductScanCount = (product, manualCount) => {
    if (!manualCount || manualCount <= 0) {
      setCantidadError("La cantidad debe ser mayor a 0");
      return false;
    }

    const currentCount = productScanCounts[product.sku_id] || 0;
    const targetCount = product.original_count;
    
    if (currentCount + parseInt(manualCount) > targetCount) {
      setCantidadError(`La cantidad excede el límite permitido`);
      return false;
    }

    const newCount = Math.min(currentCount + parseInt(manualCount), targetCount);
    
    setProductScanCounts(prev => ({
      ...prev,
      [product.sku_id]: newCount
    }));
    
    if (newCount >= targetCount) {
      setSelectedProducts(prev => {
        if (!prev.some(p => p.sku_id === product.sku_id)) {
          return [...prev, {
            sku_id: product.sku_id,
            name: product.description,
          }];
        }
        return prev;
      });
    } else {
      setSelectedProducts(prev => 
        prev.filter(p => p.sku_id !== product.sku_id)
      );
    }

    return true;
  };

  const findProduct = (searchValue) => {
    return ingressDetail?.find(
      (product) => 
        product.sku_id === searchValue || 
        product.ean_id === searchValue
    );
  };

  const handleBarcodeScan = (event) => {
    if (isQuantityFocused) {
      return;
    }

    event.preventDefault();

    if (event.key === "Enter") {
      if (scannedData.trim()) {
        const existingProduct = findProduct(scannedData.trim());

        if (existingProduct) {
          setLastScannedItem(existingProduct);
          setError("");
        } else {
          setError(`Producto no encontrado: ${scannedData}`);
          setLastScannedItem(null);
        }
      }
      setScannedData("");
    } else if (event.key === "Backspace") {
      setScannedData((prev) => prev.slice(0, -1));
    } else if (event.key.length === 1) {
      setScannedData((prev) => prev + event.key.toUpperCase());
    }
  };

  const handleAddProduct = () => {
    if (!lastScannedItem) {
      setError("Debe escanear un producto primero");
      return;
    }

    if (updateProductScanCount(lastScannedItem, cantidad)) {
      resetCurrentScan();
    }
  };

  const handleManualInput = (event) => {
    setScannedData(event.target.value.toUpperCase());
    setError("");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isQuantityFocused) {
        handleBarcodeScan(event);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [scannedData, ingressDetail, cantidad, isQuantityFocused]);

  const getScanProgress = (product) => {
    const scanned = productScanCounts[product.sku_id] || 0;
    return `${scanned}/${product.original_count}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="none"
      sx={{
        "& .MuiDialog-paper": {
          width: "1000px",
          padding: "16px",
        },
      }}
    >
      <Grid container>
        <DialogTitle>CONFIRMAR PEDIDO</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">Producto</Typography>
            <TextField
              value={scannedData}
              onChange={handleManualInput}
              placeholder="SKU o EAN"
              fullWidth
              error={!!error}
              helperText={error}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                },
                '& .MuiFormHelperText-root': {
                  marginTop: '2px',
                }
              }}
              inputProps={{
                style: { textTransform: 'uppercase' }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">Cantidad</Typography>
            <TextField
              value={cantidad}
              placeholder=" "
              fullWidth
              disabled={!lastScannedItem}
              error={!!cantidadError}
              helperText={cantidadError}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                },
                '& .MuiFormHelperText-root': {
                  marginTop: '2px',
                }
                ,
                '& .Mui-error': {
                  margin: '0px',
                }
              }}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onFocus={() => setIsQuantityFocused(true)}
              onBlur={() => setIsQuantityFocused(false)}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[0-9]*$/;

                if (regex.test(value) || value === "") {
                  if (validateQuantity(value, lastScannedItem)) {
                    setCantidad(value);
                  } else {
                    setCantidad(value);
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">&nbsp;</Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={!lastScannedItem || !cantidad || !!cantidadError}
              onClick={handleAddProduct}
              fullWidth
              sx={{ height: '40px' }}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item xs={12}>
            {lastScannedItem && (
              <Typography  gutterBottom>
                Producto escaneado: {lastScannedItem.description} (
                {getScanProgress(lastScannedItem)})
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>SKU</strong>
            </TableCell>
            <TableCell>
              <strong>EAN</strong>
            </TableCell>
            <TableCell width={500}>
              <strong>Descripción</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Cantidad</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Escaneado</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Completo</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(ingressDetail) &&
            ingressDetail.map((producto, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    lastScannedItem?.sku_id === producto.sku_id
                      ? "rgba(25, 118, 210, 0.08)"
                      : "inherit",
                }}
              >
                <TableCell>{producto.sku_id}</TableCell>
                <TableCell>{producto.ean_id}</TableCell>
                <TableCell>{producto.description}</TableCell>
                <TableCell align="center">{producto.original_count}</TableCell>
                <TableCell align="center">
                  {productScanCounts[producto.sku_id] || 0}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    disabled={true}
                    checked={selectedProducts.some(
                      (selectedProduct) =>
                        selectedProduct.sku_id === producto.sku_id
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <DialogActions>
        <Button onClick={onClose} color="error">
          Cerrar
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          disabled={loading || selectedProducts.length !== ingressDetail.length}
        >
          {loading ? "Cargando..." : "Confirmar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPrepared;