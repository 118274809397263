import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import file from "../../../components/public/Template_productos.xlsx"
import productsServices from "../../../services/productsService";

const LoadMassive = ({ open, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertObj, setAlertObj] = useState({
    severity: "success",
    message: "",
  });
  const fileInputRef = useRef(null);
  const user_id = sessionStorage.getItem("User");


  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const validateFile = (file) => {
    const allowedTypes = [".csv", ".xlsx"];
    const maxSize = 5 * 1024 * 1024;

    const fileExtension = "." + file.name.split(".").pop();

    if (!allowedTypes.includes(fileExtension)) {
      setUploadError("Solo se permiten archivos CSV y Excel");
      return false;
    }

    if (file.size > maxSize) {
      setUploadError("El archivo no debe superar 5MB");
      return false;
    }

    return true;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && validateFile(file)) {
      setSelectedFile(file);
      setUploadError(null);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const file = event.dataTransfer.files[0];

    if (file && validateFile(file)) {
      setSelectedFile(file);
      setUploadError(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadError("Por favor, selecciona un archivo");
      return;
    }

    try {
      const base64File = await convertToBase64(selectedFile);
      
      const payload = {
        user_id: parseInt(user_id,10),
        file: base64File,
        file_name: selectedFile.name,
      };

      let response = await productsServices.postProductMassive(payload);
      
      if(response?.data){
        setAlertObj({
          severity: "success",
          message: "Archivo subido exitosamente.",
        });
      }
      
      setUploadProgress(0);
      const totalSteps = 100;

      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress >= totalSteps) {
            clearInterval(uploadInterval);
            setSnackbarOpen(true);
            onClose();
            return totalSteps;
          }
          return prevProgress + 10;
        });
      }, 200);

    } catch (error) {
      setAlertObj({
        severity: "error",
        message: "Error al subir el archivo.",
      });
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    setUploadError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          Carga Masiva
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="body1"
            align="start"
            sx={{ mb: 2, fontSize: "15px" }}
          >
            Arrastre y suelte archivos CSV o Excel, o haga clic para seleccionar
          </Typography>
          <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: "2px dashed",
              borderColor: isDragActive ? "primary.main" : "grey.300",
              borderRadius: 2,
              p: 7,
              textAlign: "center",
              bgcolor: isDragActive ? "primary.light" : "background.default",
              transition: "all 0.3s ease",
              transform: isDragActive ? "scale(1.05)" : "scale(1)",
            }}
          >
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
              ref={fileInputRef}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Seleccionar Archivo
              </Button>
            </label>

            {selectedFile && (
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Typography variant="body2">{selectedFile.name}</Typography>
                <IconButton size="small" onClick={handleClearFile}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
          <Typography
            component="a"
            href={file}
            download 
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              mb: 2,
              mt: 1,
              fontSize: 15,
              textDecoration: "none",
              color: "inherit",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            Descargar ejemplo
            <DownloadIcon sx={{ ml: 1 }} />
          </Typography>

          {uploadError && (
            <Box
              mt={2}
              sx={{
                bgcolor: "error.light",
                color: "error.contrastText",
                p: 1,
                borderRadius: 1,
              }}
            >
              {uploadError}
            </Box>
          )}
          {uploadProgress > 0 && (
            <Box mt={2}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!selectedFile || uploadProgress > 0}
            fullWidth
            sx={{ mt: 2 }}
          >
            Subir Archivo
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertObj.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoadMassive;