import React from 'react';
import MaterialTable from "@material-table/core";
import { tableIcons } from "../../helpers/materialTableIcons";

const CustomMaterialTable = ({
  title,
  data,
  columns,
  style,
  showOptions = true,
  mensajeDataArrayVacio,
  options,
}) => {
  // Combinar opciones con configuración para ocultar la barra de título
  const tableOptions = {
    ...options,
    draggable: false,
    resizable: false,
    sorting: false,
    headerStyle: {
      textAlign: 'center',
      padding: "12px", // Ajusta el padding según lo necesites
    },
    cellStyle: {
      textAlign: 'center',
    },
    rowStyle: {
      overflowWrap: "break-word",
    },
    actionsColumnIndex: -1,
    search: false,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20],
    paginationType: "normal",
    toolbar: title === false, // Esta es la clave: desactiva la barra de herramientas cuando title es null
    showTitle: title === false, // También asegura que no se muestre el título
  };
  
  return (
    <MaterialTable
      style={style}
      title={title}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={tableOptions}
      localization={{
        body: {
          emptyDataSourceMessage: mensajeDataArrayVacio || 'No hay datos para mostrar',
          addTooltip: 'Añadir',
          deleteTooltip: 'Eliminar',
          editTooltip: 'Editar',
          filterRow: {
            filterTooltip: 'Filtrar'
          },
          editRow: {
            deleteText: '¿Estás seguro de eliminar este elemento?',
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Guardar'
          }
        },
        header: {
          actions: 'Acciones'
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRows: "filas",
          labelRowsPerPage: 'Filas por página:',
          firstAriaLabel: 'Primera página',
          firstTooltip: 'Primera página',
          previousAriaLabel: 'Página anterior',
          previousTooltip: 'Página anterior',
          nextAriaLabel: 'Página siguiente',
          nextTooltip: 'Página siguiente',
          lastAriaLabel: 'Última página',
          lastTooltip: 'Última página'
        }
      }}
    />
  );
};

export default CustomMaterialTable;