import { Box } from "@mui/material";
import CustomModal from "../../../components/customModal";
import React, { useEffect, useState } from "react";
import productsServices from "../../../services/productsService";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { formatDateString } from "../../../helpers";

export default function HistorialModal(props) {
  const { open, setOpen, productoSKU } = props;
  const [historial, setHistorial] = useState([]);
  const [loading, setLoading] = useState(true);

  const CustomGridToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          paddingY: "14px",
          paddingX: "16px",
        }}
      >
        {/* <GridToolbarExport
          csvOptions={{ fileName: productoSKU + "_historial" }}
          text="Exportar"
        /> */}
        <GridToolbarQuickFilter placeholder="Buscar..." />
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (productoSKU && open) {
      productsServices.getHistory(productoSKU).then((resp) => {
        const movimientosOrdenados = resp.data.results.sort((a, b) => 
          new Date(a.date_created) - new Date(b.date_created)
        );
  
        const historialConStock = movimientosOrdenados.map((movimiento, index, array) => {
          let stockActual = 0;
          
          for (let i = 0; i <= index; i++) {
            const mov = array[i];
            const cantidad = mov.movement_details[0]?.effective_count || 0;
            
            if (mov.type === "in") {
              stockActual += cantidad;
            } else if (mov.type === "out") {
              stockActual -= cantidad;
            }
          }
  
          return {
            ...movimiento,
            stockTotal: stockActual
          };
        });
  
        setHistorial(historialConStock);
        setLoading(false);
      });
    }
  }, [open]);

  function handleOnClose() {
    setLoading(true);
    setHistorial([]);
    setOpen(false);
  }

  const columns = [
    {
      headerName: "Fecha",
      field: "date_created",
      width: 100,
      flex: 1,
      renderCell: (params) => formatDateString(params.row.date_created)
    },
    {
      headerName: "Comprobante Asociado",
      field: "receipt_id",
      flex: 1,
      width: 100,
    },
    {
      headerName: "Ingreso",
      field: "ingreso",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        if (params.row?.type === "in" && params.row?.movement_details?.[0]) {
          return params.row.movement_details[0].effective_count;
        }
        return "-";
      }
    },
    {
      headerName: "Egreso",
      field: "egreso",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        if (params.row?.type === "out" && params.row?.movement_details?.[0]) {
          return params.row.movement_details[0].effective_count;
        }
        return "-";
      }
    },
    { 
      headerName: "Stock Total", 
      field: "stockTotal",
      flex: 1, 
      width: 100,
      renderCell: (params) => params.row.stockTotal || 0
    },
  ];

  return (
    <CustomModal
      open={open}
      onClose={handleOnClose}
      title="Historial"
      disabledAutoFocus={true}
    >
      <Box sx={{ width: "100%", height: "70vh" }}>
        <DataGrid
          rows={historial}
          columns={columns}
          pageSize={5}
          loading={loading}
          disableSelectionOnClick
          slots={{ toolbar: CustomGridToolbar }}
          getRowId={(row) => historial.indexOf(row)}
          style={{
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            overflowY: "scroll",
            height: "100%",
            padding: "2px",
          }}
          localeText={{
            toolbarExport: "Exportar",
            noRowsLabel: "No hay registros",
            toolbarExportCSV: "Exportar a CSV",
            toolbarExportPrint: "Imprimir",
          }}
        />
      </Box>
    </CustomModal>
  );
}
