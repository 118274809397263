import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import { Container, CssBaseline, Paper, Snackbar, Alert } from "@mui/material";
import Logo from "./logo";
import CircularProgress from "@mui/material/CircularProgress";

const LoginForm = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setError(false);
  };

  const handleLogin = async (e) => {
    if (e) e.preventDefault();

    if (!user || !password) {
      setErrorMessage("Usuario y contraseña son requeridos");
      setError(true);
      return;
    }

    setLoadingLogin(true);

    try {
      let login = await AuthService.singIn({
        name: user,
        password: password,
      });

      if (login.status === 200 && login.data.token) {
        navigate("/home");
      } 
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Usuario o contraseña incorrectos.");
      }
      else{
        setErrorMessage("Error en el servidor. Intente nuevamente más tarde.");
      } 
      setError(true);
    } finally {
      setLoadingLogin(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && user && password && !loadingLogin) {
      e.preventDefault();
      handleLogin();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [user, password, loadingLogin]);

  useEffect(() => {
    const inputs = document.querySelectorAll('input[type="password"]');
    inputs.forEach(input => {
      input.setAttribute('autocomplete', 'new-password');
      input.setAttribute('data-lpignore', 'true');
      input.setAttribute('data-form-type', 'other');
    });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper
        elevation={3}
        sx={{
          marginTop: "3rem",
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#1E1E1EFF",
        }}
      >
        <Logo />
        <form 
          onSubmit={handleLogin} 
          style={{ width: "100%" }} 
          autoComplete="off" 
          method="post"
          noValidate
        >
          <TextField
            label="Usuario"
            variant="outlined"
            fullWidth
            margin="normal"
            value={user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
            name="username"
            autoComplete="off"
            inputProps={{
              autoComplete: "username-not-saved",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              style: { color: "white" },
              autoComplete: "off",
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="current-password"
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password",
              "data-lpignore": "true",
              "data-form-type": "other",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              style: { color: "white" },
              autoComplete: "new-password",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            style={{ height: "40px" }}
            disabled={loadingLogin}
          >
            {loadingLogin ? (
              <CircularProgress size={25} title="Cargando" color="inherit" />
            ) : (
              "Login"
            )}
          </Button>
          <p style={{ color: "white" }}>version 0.8</p>
        </form>
      </Paper>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginForm;