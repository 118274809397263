import React, { useState, useEffect } from "react";
import CustomMaterialTable from "../../components/MaterialTable";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import {
  Tooltip,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Switch,
  TablePagination,
  Box,
  Skeleton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import productsServices from "../../services/productsService";
import PrintIcon from "@mui/icons-material/Print";
import ProductLabel from "./productos/productLabel";
import { Padding } from "@mui/icons-material";

const initialAlert = {
  severity: "success",
  message: "",
};

// Componente de Skeleton para la tabla
const TableSkeleton = ({ columns, rowsNum }) => {
  return (
    <TableContainer component={Paper} sx={{ width: "100%", marginTop: "20px" }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                width={column.width}
                align="center" 
                sx={{ textAlign: "center" }}
              >
                {typeof column.title === "string" ? (
                  column.title
                ) : column.title &&
                  column.title.props &&
                  column.title.props.children ? (
                  column.title
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Skeleton animation="wave" height={40} />
                  </div>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rowsNum)].map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell key={`${rowIndex}-${colIndex}`}>
                  <Skeleton animation="wave" height={24} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TablaInventario = ({
  handleOpenModalEdit,
  handleOpenModalHistorial,
  handleCreateProducto,
  products,
  searchInventory,
  filterText,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingIds, setLoadingIds] = useState([]);
  const [isEnabling, setIsEnabling] = useState(false);
  const [printLabelOpen, setPrintLabelOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState({ sku: "", name: "" });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (products && products.paging) {
      setTotalItems(products.paging.total || 0);
    }
  }, [products]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleCreateProducto(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    handleCreateProducto(1, newRowsPerPage);
  };

  const handleOpenConfirmDialog = (id, currentStatus) => {
    setSelectedProductId(id);
    setIsEnabling(!currentStatus);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSelectedProductId(null);
    setIsEnabling(false);
    setOpenConfirmDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleToggleStatus = async () => {
    setLoading(true);
    setLoadingIds((prev) => [...prev, selectedProductId]);

    try {
      if (selectedProductId) {
        if (isEnabling) {
          await productsServices.enableProduct(selectedProductId);
          setAlertObj({
            severity: "success",
            message: "Producto habilitado exitosamente.",
          });
        } else {
          await productsServices.deleteProduct(selectedProductId);
          setAlertObj({
            severity: "success",
            message: "Producto inhabilitado exitosamente.",
          });
        }
      }
      handleCreateProducto(page + 1, rowsPerPage);
    } catch (error) {
      console.error("Error al cambiar el estado del producto:", error);
      setAlertObj({
        severity: "error",
        message: "Hubo un error al intentar cambiar el estado del producto.",
      });
    } finally {
      setSnackbarOpen(true);
      setLoadingIds((prev) =>
        prev.filter((loadingId) => loadingId !== selectedProductId)
      );
      handleCloseConfirmDialog();
      setLoading(false);
    }
  };

  const handlePrintLabel = (ean, name) => {
    setSelectedLabel({ ean, name });
    setPrintLabelOpen(true);
  };

  const columns = [
    {
      title: "SKU",
      field: "sku_id",
      sorting: false,
      width: 100,
    },
    {
      title: "EAN",
      field: "ean_id",
      sorting: false,
      width: 100,
    },
    {
      title: "Tipo",
      field: "tipo",
      sorting: false,
      width: 100,
      render: (rowData) => {
        if (rowData.is_combo) {
          return "Combo";
        }
        if (rowData.is_multipack) {
          return "Multibulto";
        }
        return "Convencional";
      },
    },
    {
      title: "Nombre",
      field: "name",
      sorting: false,
      width: 350,
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          Pendiente <br /> Ingreso
        </div>
      ),
      field: "stock_in",
      sorting: false,
      width: 50,
    },
    {
      title: "Stock",
      field: "stock",
      sorting: false,
      width: 50,
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          Pendiente <br /> Egreso
        </div>
      ),
      field: "stock_out",
      sorting: false,
      width: 50,
    },
    {
      title: "Acciones",
      sorting: false,
      width: 200,
      render: (rowData) => (
        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Imprimir etiqueta">
            <IconButton
              aria-label="Imprimir etiqueta"
              onClick={() => {
                handlePrintLabel(rowData.ean_id, rowData.name);
              }}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton
              aria-label="editar"
              onClick={() => {
                handleOpenModalEdit(rowData);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Historial">
            <IconButton
              aria-label="historial"
              onClick={() => {
                handleOpenModalHistorial(rowData);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={rowData.active ? "Inhabilitar" : "Habilitar"}>
            <Switch
              checked={rowData.active}
              onClick={() =>
                handleOpenConfirmDialog(rowData.id, rowData.active)
              }
              size="small"
              color="primary"
            />
          </Tooltip>
        </Grid>
      ),
    },
  ];

  // Extraer los resultados de los productos
  const productResults = products?.results || [];

  return (
    <>
      {searchInventory ? (
        // Mostrar skeleton mientras se cargan los datos
        <TableSkeleton columns={columns} rowsNum={rowsPerPage} />
      ) : (
        <>
          <CustomMaterialTable
            data={productResults}
            columns={columns}
            style={{ width: "100%", marginTop: "30px", Padding: "10px" }}
            mensajeDataArrayVacio="No hay inventario disponible"
            options={{
              paging: false, // Desactivamos la paginación interna de MaterialTable
            }}
          />

          {/* Componente de paginación personalizado */}
          {productResults.length > 0 && (
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              labelRowsPerPage="Filas por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          )}
        </>
      )}

      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmar acción</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isEnabling
              ? "¿Estás seguro de que desea habilitar este producto?"
              : "¿Estás seguro de que desea inhabilitar este producto?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            color="error"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleToggleStatus}
            color={isEnabling ? "primary" : "error"}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Confirmar"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertObj.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
      <ProductLabel
        open={printLabelOpen}
        setOpen={setPrintLabelOpen}
        ean={selectedLabel.ean}
        name={selectedLabel.name}
      />
    </>
  );
};

export default TablaInventario;
