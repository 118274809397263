import React from "react";
import {
  Alert,
  Button,
  Grid,
  Snackbar,
  TextField,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import productsService from "../../../services/productsService";
const initialAlert = {
  severity: "success",
  message: "",
};
const CrearProducto = ({
  handleCreateProducto,
  productoSKU,
  handleCloseModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [sku, setSku] = useState("");
  const [nombre, setNombre] = useState("");
  const [ean, setEan] = useState("");
  const [alto, setAlto] = useState("");
  const [largo, setlargo] = useState("");
  const [ancho, setAncho] = useState("");
  const [peso, setPeso] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const userId = sessionStorage.getItem("User");
  const [error, setError] = useState({
    sku: "",
    nombre: "",
    ean: "",
    alto: "",
    largo: "",
    ancho: "",
    peso: "",
    observaciones: "",
  });

  useEffect(() => {
    if (productoSKU) {
      setLoadingSpinner(true);
      productsService
        .getProductsBySku(productoSKU)
        .then((res) => {
          let producto = Array.isArray(res.data) ? res.data[0] : res.data;

          if (producto) {
            setId(String(producto.id));
            setSku(String(producto.sku_id));
            setNombre(producto.name);
            setEan(producto.ean_id);
            setAlto(String(producto.height));
            setlargo(String(producto.deep));
            setAncho(String(producto.width));
            setPeso(String(producto.weight));
            setObservaciones(producto.observation);
            setIsChecked(producto.print_tag);
          } else {
            setAlertObj({
              severity: "error",
              message: "El producto no se encontró.",
            });
            setOpen(true);
          }
        })
        .catch(() => {
          setAlertObj({
            severity: "error",
            message: "Ocurrió un error al obtener el producto.",
          });
          setOpen(true);
        })
        .finally(() => {
          setLoadingSpinner(false);
        });
    }
  }, [productoSKU]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function validarFormulario() {
    let isValid = true;
    // Lógica para crear el producto aquí
    let newError = {
      sku: "",
      nombre: "",
      ean: "",
      alto: "",
      largo: "",
      ancho: "",
      peso: "",
      observaciones: "",
    };
    if (sku.trim() === "") {
      newError.sku = "El sku es obligatorio";
      isValid = false;
    }

    if (nombre.trim() === "") {
      newError.nombre = "El nombre es obligatorio";
      isValid = false;
    }

    if (ean.trim() === "") {
      newError.ean = "El ean es obligatorio";
      isValid = false;
    }

    if (largo.trim() === "" || isNaN(largo) || parseInt(largo, 10) <= 0) {
      newError.largo = "El largo debe ser un número positivo";
      isValid = false;
    }
    if (ancho.trim() === "" || isNaN(ancho) || parseInt(ancho, 10) <= 0) {
      newError.ancho = "El ancho debe ser un número positivo";
      isValid = false;
    }
    if (alto.trim() === "" || isNaN(alto) || parseInt(alto, 10) <= 0) {
      newError.alto = "El alto debe ser un número positivo";
      isValid = false;
    }
    if (peso.trim() === "" || isNaN(peso) || parseInt(peso, 10) <= 0) {
      newError.peso = "El peso debe ser un número positivo";
      isValid = false;
    }

    setError(newError);

    // Si hay errores, no realiza la acción de crear el producto
    if (!isValid) {
      return false;
    }

    return true;
  }

  async function handleEditProduct() {
    if (!validarFormulario()) {
      return;
    }
    try {
      setLoading(true);
      let newProduct = await productsService.putProducts({
        id: parseInt(id),
        sku_id: sku,
        name: nombre,
        ean_id: ean,
        height: parseFloat(alto),
        deep: parseFloat(largo),
        width: parseFloat(ancho),
        weight: parseFloat(peso),
        observation: observaciones,
        print_tag: JSON.parse(isChecked),
        user_id: parseInt(userId, 10),
      });
      if (newProduct?.data) {
        setAlertObj({
          severity: "success",
          message: "Producto editado exitosamente",
        });
        setLoading(false);
        setOpen(true);
        setTimeout(() => {
          handleCreateProducto();
          handleCloseModal();
        }, 2000);
      }
    } catch {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error en la edición",
      });
      setLoading(false);
      setOpen(true);
    }
  }

  const handleCreateProduct = async () => {
    if (!validarFormulario()) {
      return;
    }
    try {
      setLoading(true);
      let newProduct = await productsService.postProduct({
        user_id: parseInt(userId, 10),
        sku_id: sku,
        name: nombre,
        ean_id: ean,
        height: parseFloat(alto),
        deep: parseFloat(largo),
        width: parseFloat(ancho),
        weight: parseFloat(peso),
        observation: observaciones,
        checked: isChecked,
      });
      if (newProduct?.data) {
        setAlertObj({
          severity: "success",
          message: "Se creó el producto exitosamente",
        });
        setLoading(false);
        setOpen(true);
        setTimeout(() => {
          handleCreateProducto();
          handleCloseModal();
        }, 2000);
      }
    } catch (error) {
      const errorMessage = error.response?.data || error.message;

      setAlertObj({
        severity: "error",
        message: errorMessage.includes("sku_id ya está registrado")
          ? "El SKU ingresado ya existe."
          : "Ocurrió un error en la creación.",
      });

      setLoading(false);
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const clearForm = () => {
    handleCloseModal();
  };

  return (
    <div style={{ position: "relative" }}>
      {loadingSpinner && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 10,
          }}
        >
          <CircularProgress size={60} />
        </div>
      )}
      <Grid
        container
        spacing={1}
        sx={{
          overflowY: "auto",
          maxHeight: { xs: "80vh", sm: "80vh" },
        }}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            label="SKU *"
            variant="outlined"
            disabled={productoSKU}
            fullWidth
            name={"sku"}
            value={sku}
            onChange={(e) => {
              const inputValue = e.target.value.toUpperCase();
              if (inputValue.length > 14) return;
              setSku(inputValue);
              setError({ ...error, sku: "" });
            }}
            error={Boolean(error.sku)}
            helperText={error.sku}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Nombre *"
            variant="outlined"
            fullWidth
            value={nombre}
            onChange={(e) => {
              setNombre(e.target.value.toUpperCase());
              setError({ ...error, nombre: "" });
            }}
            error={Boolean(error.nombre)}
            helperText={error.nombre}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="EAN *"
            variant="outlined"
            fullWidth
            value={ean}
            onChange={(e) => {
              const regex = /^[a-zA-Z0-9]*$/;
              const inputValue = e.target.value.toUpperCase();

              if (inputValue.length > 14) return;
              if (!regex.test(inputValue)) return;

              setEan(inputValue);
              setError({ ...error, ean: "" });
            }}
            error={Boolean(error.ean)}
            helperText={error.ean}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Peso en gramos *"
            variant="outlined"
            fullWidth
            value={peso}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/;

              if (regex.test(value) || value === "") {
                setPeso(value);
                setError({ ...error, peso: "" });
              }
            }}
            error={Boolean(error.peso)}
            helperText={error.peso}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Alto en cm *"
            variant="outlined"
            fullWidth
            value={alto}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/;

              if (regex.test(value) || value === "") {
                setAlto(value);
                setError({ ...error, alto: "" });
              }
            }}
            error={Boolean(error.alto)}
            helperText={error.alto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Largo en cm *"
            variant="outlined"
            fullWidth
            value={largo}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/;

              if (regex.test(value) || value === "") {
                setlargo(value);
                setError({ ...error, largo: "" });
              }
            }}
            error={Boolean(error.largo)}
            helperText={error.largo}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Ancho en cm *"
            variant="outlined"
            fullWidth
            value={ancho}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9]*$/;

              if (regex.test(value) || value === "") {
                setAncho(value);
                setError({ ...error, ancho: "" });
              }
            }}
            error={Boolean(error.ancho)}
            helperText={error.ancho}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Observaciones"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={observaciones}
            onChange={(e) => setObservaciones(e.target.value)}
            error={Boolean(error.observaciones)}
            helperText={error.observaciones}
            inputProps={{
              maxLength: 160,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} style={{ display: "flex" }}>
          <p>Necesita Reetiquetado</p>
          <Checkbox
            checked={isChecked} 
            onChange={(e) => setIsChecked(e.target.checked)}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={clearForm}
              sx={{ marginRight: "2vw" }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={
                loading
                  ? () => {}
                  : productoSKU
                    ? handleEditProduct
                    : handleCreateProduct
              }
            >
              {loading ? (
                <CircularProgress
                  size={20}
                  style={{ marginRight: 10, color: "white" }}
                />
              ) : productoSKU ? (
                "Editar"
              ) : (
                "Crear"
              )}
            </Button>
          </div>
        </Grid>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={alertObj?.severity}
            sx={{ width: "100%" }}
          >
            {alertObj.message}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default CrearProducto;
