import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Card,
  CardContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  IconButton,
  Paper
} from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

const ProductLabel = ({ open, setOpen, ean, name }) => {
  const [quantity, setQuantity] = useState("");
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    if (open) {
      setQuantity("");
      setIsPrinting(false);
    }
  }, [open]);

  const handleClose = () => {
    if (!isPrinting) {
      setOpen(false);
    }
  };

  const handlePrint = async () => {
    try {
      setIsPrinting(true);

      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      const labels = Array(parseInt(quantity)).fill(null);

      const labelContent = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
            <title>Product Labels</title>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jsbarcode/3.11.5/JsBarcode.all.min.js"></script>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 10px;
                box-sizing: border-box;
              }
              .page-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: flex-start;
              }
              .label-container {
                border: 1px solid #000;
                padding: 10px;
                width: 320px;
                height: 150px;
                margin: 5px;
                box-sizing: border-box;
                page-break-inside: avoid;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }
              .text-content {
                flex: 0 0 auto;
              }
              .ean, .name {
                font-size: 16px;
                margin-bottom: 5px;
                text-align: center;
              }
              .ean {
                font-weight: bold;
              }
              .barcode-container {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0;
              }
              .barcode-container svg {
                max-width: 100%;
                height: 60px;
              }
              @media print {
                @page {
                  margin: 0;
                  size: A4;
                }
                body {
                  margin: 1cm;
                }
                .label-container {
                  page-break-inside: avoid;
                }
              }
            </style>
          </head>
          <body>
            <div class="page-container">
              ${labels
                .map(
                  (_, index) => `
                <div class="label-container">
                  <div class="text-content">
                    <div class="ean">EAN: ${ean}</div>
                    <div class="name">${name}</div>
                  </div>
                  <div class="barcode-container">
                    <svg id="barcode-${index}"></svg>
                  </div>
                </div>
              `
                )
                .join("")}
            </div>
            <script>
              document.addEventListener('DOMContentLoaded', function() {
                document.querySelectorAll('[id^="barcode-"]').forEach((el) => {
                  JsBarcode(el, "${ean}", {
                    format: "CODE39",
                    width: 1.5,
                    height: 60,
                    displayValue: false,
                    margin: 5,
                    fontSize: 14
                  });
                });
                
                if (window.parent) {
                  window.parent.postMessage('readyToPrint', '*');
                }
              });
            </script>
          </body>
        </html>
      `;

      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(labelContent);
      iframe.contentWindow.document.close();

      await new Promise((resolve) => {
        const messageListener = (event) => {
          if (event.data === 'readyToPrint') {
            window.removeEventListener('message', messageListener);
            resolve();
          }
        };
        
        window.addEventListener('message', messageListener);
        
        setTimeout(resolve, 3000);
      });

      const printWindow = iframe.contentWindow;
      printWindow.print();

      await new Promise(resolve => setTimeout(resolve, 2000));
      
      document.body.removeChild(iframe);
      setIsPrinting(false);
      handleClose();

    } catch (error) {
      console.error('Error during print process:', error);
      setIsPrinting(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        pb: 1
      }}>
        <LocalOfferIcon sx={{ fontSize: 22 }} />
        Imprimir Etiquetas
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Configure la cantidad de etiquetas a imprimir
        </Typography>

        <Card variant="outlined">
          <CardContent>
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                bgcolor: 'grey.50'
              }}
            >
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {name}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1 
              }}>
                <Typography variant="subtitle2">EAN:</Typography>
                <Box
                  component="code"
                  sx={{
                    bgcolor: 'grey.100',
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    fontSize: '0.875rem'
                  }}
                >
                  {ean}
                </Box>
              </Box>
            </Paper>

            <TextField
              label="Cantidad de etiquetas"
              value={quantity}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setQuantity(value === "" ? "" : parseInt(value, 10));
                }
              }}
              fullWidth
              margin="normal"
              InputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*"
              }}
            />
          </CardContent>
        </Card>
      </DialogContent>

      <DialogActions sx={{ p: 2, pt: 1 }}>
        <Button
          onClick={handleClose}
          color="error"
          variant="outlined"
          disabled={isPrinting}
        >
          Cancelar
        </Button>
        <Button
          onClick={handlePrint}
          variant="contained"
          color="primary"
          disabled={!quantity || quantity < 1 || isPrinting}
          startIcon={<PrintIcon />}
        >
          {isPrinting ? 'Imprimiendo...' : 'Imprimir'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductLabel;