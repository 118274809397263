import React, { useEffect, useState } from 'react';

const LabelPrinter = ({ selectedProducts }) => {
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrintLabels = async () => {
    try {
      setIsPrinting(true);

      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      const labelContent = `
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
            <title>Product Labels</title>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/jsbarcode/3.11.5/JsBarcode.all.min.js"></script>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 10px;
                box-sizing: border-box;
              }
              .page-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: flex-start;
              }
              .label-container {
                border: 1px solid #000;
                padding: 10px;
                width: 320px;
                height: 150px;
                margin: 5px;
                box-sizing: border-box;
                page-break-inside: avoid;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }
              .text-content {
                flex: 0 0 auto;
              }
              .ean, .name {
                font-size: 16px;
                margin-bottom: 5px;
                text-align: center;
              }
              .ean {
                font-weight: bold;
              }
              .barcode-container {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0;
              }
              .barcode-container svg {
                max-width: 100%;
                height: 60px;
              }
              @media print {
                @page {
                  margin: 0;
                  size: A4;
                }
                body {
                  margin: 1cm;
                }
                .label-container {
                  page-break-inside: avoid;
                }
              }
            </style>
          </head>
          <body>
            <div class="page-container">
              ${selectedProducts.map((product, index) => `
                <div class="label-container">
                  <div class="text-content">
                    <div class="ean">EAN: ${product.ean_id}</div>
                    <div class="name">${product.name}</div>
                  </div>
                  <div class="barcode-container">
                    <svg id="barcode-${index}"></svg>
                  </div>
                </div>
              `).join("")}
            </div>
            <script>
              document.addEventListener('DOMContentLoaded', function() {
                const products = ${JSON.stringify(selectedProducts)};
                document.querySelectorAll('[id^="barcode-"]').forEach((el, index) => {
                  JsBarcode(el, products[index].ean_id, {
                    format: "CODE39",
                    width: 1.5,
                    height: 60,
                    displayValue: false,
                    margin: 5,
                    fontSize: 14
                  });
                });
                
                window.print();
              });
            </script>
          </body>
        </html>
      `;

      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(labelContent);
      iframe.contentWindow.document.close();

      await new Promise(resolve => setTimeout(resolve, 2000));
      
      document.body.removeChild(iframe);
      setIsPrinting(false);

    } catch (error) {
      console.error('Error during print process:', error);
      setIsPrinting(false);
    }
  };

  useEffect(() => {
    if (selectedProducts?.length && !isPrinting) {
      handlePrintLabels();
    }
  }, [selectedProducts, isPrinting]);

  return null;
};

export default LabelPrinter;