import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  Divider,
  Box,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";
import userService from "../../../services/userService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getTranslatedPermissionLabel = (permission) => {
  const tabTranslations = {
    movement_in: "Ingresos",
    movement_out: "Egresos",
    product: "Productos",
    user: "Usuarios",
    role: "Roles",
    rol_permission: "Configuración de Roles",
  };

  const controlTranslations = {
    create: "Crear",
    read: "Leer",
    update: "Actualizar",
    delete: "Eliminar",
    status_change: "Cambiar Estado",
    assign: "Asignar",
  };

  const translatedTab = tabTranslations[permission.tab] || permission.tab;
  const translatedControl =
    controlTranslations[permission.control] || permission.control;

  return `${translatedTab} - ${translatedControl}`;
};

const AddUserModal = ({ open, handleClose, addUser, editingUser = null }) => {
  const defaultFormState = {
    name: "",
    email: "",
    permissions: [],
    password: "",
    confirmPassword: "",
  };

  const [user, setUser] = useState(defaultFormState);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [Permissions, setPermissions] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  useEffect(() => {
    const fetchPermits = async () => {
      if (open) {
        try {
          const response = await userService.getPermits();
          setPermissions(response.data);
        } catch (error) {
          console.error("Error al obtener permisos:", error);
        }
      }
    };
    fetchPermits();
  }, [open]);

  useEffect(() => {
    if (editingUser) {
      setUser({
        ...editingUser,
        permissions: editingUser.permissions || [],
        password: "",
        confirmPassword: "",
      });
      setIsEditing(true);
    } else {
      setUser(defaultFormState);
      setIsEditing(false);
    }
  }, [editingUser, open]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!user.name.trim()) {
      newErrors.name = "El nombre es obligatorio";
    }

    if (!user.email.trim()) {
      newErrors.email = "El email es obligatorio";
    } else if (!validateEmail(user.email)) {
      newErrors.email = "Email inválido";
    }

    if (user.permissions.length === 0) {
      newErrors.permissions = "Debe seleccionar al menos un permiso";
    }

    if (!isEditing) {
      if (!user.password) {
        newErrors.password = "La contraseña es obligatoria";
      } else if (user.password.length < 6) {
        newErrors.password = "La contraseña debe tener al menos 6 caracteres";
      }

      if (user.password !== user.confirmPassword) {
        newErrors.confirmPassword = "Las contraseñas no coinciden";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handlePermissionChange = (permissionId) => {
    const updatedPermissions = user.permissions.includes(permissionId)
      ? user.permissions.filter((id) => id !== permissionId)
      : [...user.permissions, permissionId];

    setUser({ ...user, permissions: updatedPermissions });
    if (updatedPermissions.length > 0 && errors.permissions) {
      setErrors({ ...errors, permissions: null });
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const controlTranslations = {
    'create': 'Crear',
    'read': 'Leer',
    'update': 'Actualizar', 
    'delete': 'Eliminar',
    'status_change': 'Cambiar Estado',
    'assign': 'Asignar'
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const userToSubmit = {
        name: user.name,
        email: user.email,
        permissions: user.permissions,
        ...(isEditing ? {} : { password: user.password }),
      };

      addUser(userToSubmit, isEditing);
      handleReset();
      handleClose();
    }
  };

  const handleReset = () => {
    setUser(defaultFormState);
    setErrors({});
  };

  const groupPermissionsByTab = (permissions) => {
    return permissions.reduce((acc, permission) => {
      if (!acc[permission.tab]) {
        acc[permission.tab] = [];
      }
      acc[permission.tab].push(permission);
      return acc;
    }, {});
  };

  const tabTranslations = {
    'movement_in': 'Ingresos',
    'movement_out': 'Egresos', 
    'product': 'Productos',
    'user': 'Usuarios',
    'role': 'Roles',
    'rol_permission': 'Configuración de Roles'
  };


  const groupedPermissions = groupPermissionsByTab(Permissions);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px",
          paddingBottom: "15px"
        }}
      >
        <Typography variant="h6">
          {isEditing ? "Editar usuario" : "Nuevo usuario"}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "24px" }}>
        <Grid container spacing={3}>
          {/* Nombre */}
          <Grid item xs={6}>
            <TextField
              name="name"
              label="Nombre completo"
              variant="outlined"
              fullWidth
              value={user.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              InputProps={{
                sx: { borderRadius: "8px" },
              }}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={6}>
            <TextField
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={user.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              InputProps={{
                sx: { borderRadius: "8px" },
              }}
            />
          </Grid>

          {!isEditing && (
            <>
              <Divider sx={{ my: 1 }} />

              <Grid item xs={12} md={6}>
                <TextField
                  name="password"
                  label="Contraseña"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={user.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    sx: { borderRadius: "8px" },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="confirmPassword"
                  label="Confirmar contraseña"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={user.confirmPassword}
                  onChange={handleChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  InputProps={{
                    sx: { borderRadius: "8px" },
                  }}
                />
              </Grid>
            </>
          )}

          {isEditing && (
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Alert severity="info">
                  Los campos de contraseña se omiten al editar un usuario
                  existente. Para cambiar la contraseña, utilice la opción
                  "Restablecer contraseña".
                </Alert>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* Permissions (checkboxes) */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={!!errors.permissions}
            component="fieldset"
            variant="outlined"
          >
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
              Permisos
            </Typography>

            {Object.entries(groupedPermissions).map(([tab, permissions]) => (
              <Accordion
                key={tab}
                expanded={expandedAccordion === tab}
                onChange={handleAccordionChange(tab)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{tabTranslations[tab] || tab}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {permissions.map((permission) => (
                      <Grid item xs={6} key={permission.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={user.permissions.includes(permission.id)}
                              onChange={() =>
                                handlePermissionChange(permission.id)
                              }
                              name={permission.id}
                              color="primary"
                            />
                          }
                          label={`${
                            controlTranslations[permission.control] ||
                            permission.control
                          }`}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
            {errors.permissions && (
              <Typography color="error" variant="caption">
                {errors.permissions}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: "16px 24px" }}>
        <Button
          onClick={handleClose}
          color="inherit"
          sx={{ borderRadius: "8px" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ borderRadius: "8px" }}
        >
          {isEditing ? "Actualizar" : "Guardar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
