import React from 'react';
import { 
  Paper, 
  Popover, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Button,
  Stack
} from '@mui/material';

const FilterModalInventory = ({ anchorEl, open, onClose, onApplyFilters }) => {
  const [active, setActive] = React.useState('');
  const [productType, setProductType] = React.useState('');

  const handleApplyFilters = () => {
    onApplyFilters({
      active
    //   productType
    });
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper sx={{ p: 2, width: 250 }}>
        <Stack spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Estado</InputLabel>
            <Select
              value={active}
              label="Estado"
              onChange={(e) => setActive(e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="true">Activos</MenuItem>
              <MenuItem value="false">No Activos</MenuItem>
            </Select>
          </FormControl>

          {/* <FormControl fullWidth size="small">
            <InputLabel>Tipo de Producto</InputLabel>
            <Select
              value={productType}
              label="Tipo de Producto"
              onChange={(e) => setProductType(e.target.value)}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="conventional">Convencional</MenuItem>
              <MenuItem value="combo">Combo</MenuItem>
              <MenuItem value="multipack">Multibulto</MenuItem>
            </Select>
          </FormControl> */}

          <Button 
            variant="contained" 
            onClick={handleApplyFilters}
            fullWidth
          >
            Aplicar Filtros
          </Button>
        </Stack>
      </Paper>
    </Popover>
  );
};

export default FilterModalInventory;