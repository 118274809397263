import client from "../client/client";

const postNuevoUsuario = async (data) => {
  let resp = client.post("users/register/", data);

  return await resp;
};

const postUpdateUsuario = async (data) => {
  let resp = client.put("users/register/", data);
  return await resp;
};
const getAllUsuarios = async () => {
  let resp = client.get("users/register/", {
    sku: "",
  });

  return resp;
};

const deleteUsuario = async (data) => {
  let resp = client.delete("users/register/", { data });
  return await resp;
};

const getDeposits = async () => {
  const userId = sessionStorage.getItem("User");
  try {
    const resp = await client.get(`warehouse-api/search/${userId}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};

const getProfile = async () => {
  try {
    const resp = await client.get("users/perfil/");
    return resp;
  } catch (error) {
    console.error("Error al obtener perfil:", error);
  }
};

const getPermits = async () => {
  try {
    const resp = await client.get("user-api/permissions");
    return resp;
  } catch (error) {
    console.error("Error al obtener perfil:", error);
  }
};

let userService = {
  postNuevoUsuario,
  getAllUsuarios,
  deleteUsuario,
  postUpdateUsuario,
  getDeposits,
  getProfile,
  getPermits
};

export default userService;
