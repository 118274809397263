import {
  Button,
  Grid,
  TextField,
  Typography,
  ListSubheader,
  ListItem,
  List,
  Checkbox,
} from "@mui/material";
import CustomMaterialTable from "../../../components/MaterialTable";
import { formatDateString } from "../../../helpers";
import { useState } from "react";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import ingressService from "../../../services/ingressService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect } from "react";
import LogoPdf from "../../../../src/assets/LogoPdf.png";

const ModalListadoIngresos = ({
  ingressDetail,
  handleCloseDetailModal,
  isEdit,
  fetchAllIngress,
  setAlertObj,
  setOpenSnack,
  onConfirmSuccess,
}) => {
  const [amountEntered, setAmountEntered] = useState({});
  const [erroresCantidad, setErroresCantidad] = useState({});
  const [inputFocus, setInputFocus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (ingressDetail && ingressDetail.movement_details) {
      const productsWithPrintTag = ingressDetail.movement_details.filter(
        (item) => item.print_tag === true
      );
      
      setSelectedProducts((prevSelected) => {
        const newSelectedProducts = [...prevSelected];
        
        productsWithPrintTag.forEach((product) => {
          const isAlreadySelected = newSelectedProducts.some(
            (selectedProduct) => selectedProduct.ean_id === product.ean_id
          );
          if (!isAlreadySelected) {
            newSelectedProducts.push({
              ean_id: product.ean_id,
              name: product.description,
            });
          }
        });
        
        return newSelectedProducts;
      });
    }
  }, [ingressDetail]); 

  const handleConfirm = async () => {
    setLoading(true);

    ingressDetail.movement_details.forEach((detalle) => {
      if (amountEntered[detalle.product_id] === undefined) {
        setAmountEntered((prevAmountEntered) => ({
          ...prevAmountEntered,
          [detalle.product_id]: detalle.effective_count,
        }));
      }
    });

    const allAmountsEntered = ingressDetail.movement_details?.every(
      (detalle) => amountEntered[detalle.product_id] !== undefined
    );

    if (!allAmountsEntered) {
      setLoading(false);
      setAlertObj({
        severity: "error",
        message: "Por favor, ingrese todas las cantidades",
      });
      setOpenSnack(true);
      return;
    }

    const ingressData = {
      id: ingressDetail.id,
      type: ingressDetail.type,
      status: "accepted",
      sub_movement_id: ingressDetail.sub_movement_id,
      receipt_id: ingressDetail.receipt_id,
      provider_name: ingressDetail.provider_name,
      warehouse_id: ingressDetail.warehouse_id,
      email: ingressDetail.email,
      observation: ingressDetail.observation,
      phone_number: ingressDetail.phone_number,
      user_id: ingressDetail.user_id,
      movement_details: ingressDetail.movement_details.map((detalle) => ({
        product_id: detalle.product_id,
        description: detalle.description,
        original_count: detalle.original_count,
        effective_count: parseInt(amountEntered[detalle.product_id] || 0),
      })),
    };

    try {
      const result = await ingressService.postRegisterIngress(ingressData);
      if (result?.data) {
        setAlertObj({
          severity: "success",
          message: "Confirmación exitosa",
        });
        handleDownloadPDF();
        setOpenSnack(true);
        onConfirmSuccess(selectedProducts);
      } else {
        setAlertObj({
          severity: "error",
          message: result.data,
        });
        setOpenSnack(true);
      }
    } catch (error) {
      console.error("Error al confirmar", error);
    } finally {
      setLoading(false);
      fetchAllIngress();
      handleCloseDetailModal();
    }
  };
  
  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedProduct) => selectedProduct.ean_id === product.ean_id
      );

      if (isSelected) {
        return prevSelected.filter(
          (selectedProduct) => selectedProduct.ean_id !== product.ean_id
        );
      } else {
        return [
          ...prevSelected,
          {
            ean_id: product.ean_id,
            name: product.description,
          },
        ];
      }
    });
  };

  const handleQuantityChange = (event, sku) => {
    const newValue = event.target.value;

    if (!/^\d*$/.test(newValue)) {
      setErroresCantidad((prevErroresCantidad) => ({
        ...prevErroresCantidad,
        [sku]: "Solo se permiten números",
      }));
      return;
    }

    setAmountEntered((prevAmountEntered) => ({
      ...prevAmountEntered,
      [sku]: newValue,
    }));

    setErroresCantidad((prevErroresCantidad) => ({
      ...prevErroresCantidad,
      [sku]: newValue.trim() === "" ? "Ingrese la cantidad" : null,
    }));

    setInputFocus(sku);
  };

  const handleDownloadPDF = async () => {
    const doc = new jsPDF();

    const optimizedLogo = await optimizeImage(LogoPdf, {
      maxWidth: 300,
      maxHeight: 240,
      quality: 0.8,
    });

    doc.setFont("Helvetica");

    const logoWidth = 25;
    const logoHeight = 20;
    doc.addImage(
      optimizedLogo,
      "PNG",
      170,
      10,
      logoWidth,
      logoHeight,
      undefined,
      "FAST"
    );

    doc.setFontSize(20);
    doc.setFont("Helvetica", "bold");
    doc.text("DETALLE DE INGRESO", 105, 20, { align: "center" });

    let verticalSpace = 55;

    doc.setFontSize(12);
    doc.setFont("Helvetica", "normal");
    doc.text("Empresa Voras", 105, 30, { align: "center" });
    doc.text("Calle 123, Ciudad de Córdoba", 105, 35, { align: "center" });
    doc.text(
      "Teléfono: (123) 456-7890 | Email: contacto@empresa.com",
      105,
      40,
      { align: "center" }
    );

    doc.line(15, 45, 195, 45);

    doc.setFontSize(10);

    doc.setFont("Helvetica", "bold");
    doc.text("Información del ingreso", 20, verticalSpace);

    doc.setFont("Helvetica", "normal");
    verticalSpace += 8;

    doc.text(
      `Fecha de Creación: ${formatDateString(ingressDetail.date_created)}`,
      20,
      verticalSpace
    );
    doc.text(`Id Orden: ${ingressDetail.id}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Proveedor: ${ingressDetail.provider_name}`, 20, verticalSpace);
    doc.text(`Teléfono: ${ingressDetail.phone_number}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Comprobante: ${ingressDetail.receipt_id}`, 20, verticalSpace);
    doc.text(`Depósito: ${ingressDetail.warehouse_id}`, 110, verticalSpace);

    verticalSpace += 5;
    doc.text(`Observaciones: ${ingressDetail.observation}`, 20, verticalSpace);
    doc.text(`Email: ${ingressDetail.email}`, 110, verticalSpace);

    verticalSpace += 10;
    doc.line(15, verticalSpace, 195, verticalSpace);

    verticalSpace += 10;
    const data = ingressDetail.movement_details.map((product) => [
      product.sku_id,
      product.description,
      product.original_count,
      amountEntered[product.product_id] || "0",
    ]);

    autoTable(doc, {
      startY: verticalSpace,
      head: [
        ["SKU", "Descripción", "Cantidad declarada", "Cantidad ingresada"],
      ],
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 90 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
      },
      styles: {
        overflow: "linebreak",
        halign: "center",
        valign: "middle",
      },
    });

    verticalSpace = doc.lastAutoTable.finalY + 10;

    doc.setFont("Helvetica", "bold");
    const totalSKU = ingressDetail.movement_details.length;

    const completedSKU = ingressDetail.movement_details.filter(
      (detail) =>
        (amountEntered[detail.product_id] || detail.effective_count || 0) > 0
    ).length;

    const totalUnidadesIngresadas1 = ingressDetail.movement_details.reduce(
      (total, detail) =>
        total +
        Number(amountEntered[detail.product_id] || detail.effective_count || 0),
      0
    );

    const totalUnidadesOriginales = ingressDetail.movement_details.reduce(
      (total, detail) => total + (detail.original_count || 0),
      0
    );

    doc.text(
      `Total de productos: ${totalSKU} / ${completedSKU}`,
      20,
      verticalSpace
    );
    verticalSpace += 5;
    doc.text(
      `Total de unidades: ${totalUnidadesOriginales} / ${totalUnidadesIngresadas1}`,
      20,
      verticalSpace
    );

    doc.save(`Detalle_ingreso_${ingressDetail.id}.pdf`);
  };

  const optimizeImage = (imageSource, options = {}) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");

        let width = img.width;
        let height = img.height;

        if (options.maxWidth && width > options.maxWidth) {
          height = (options.maxWidth * height) / width;
          width = options.maxWidth;
        }

        if (options.maxHeight && height > options.maxHeight) {
          width = (options.maxHeight * width) / height;
          height = options.maxHeight;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL("image/png", options.quality || 0.8));
      };
      img.src = imageSource;
    });
  };

  return (
    <>
      {!isEdit ? (
        <>
          <Grid container>
            <Grid item xs={4}>
              <Typography>
                Id Orden: <b>{ingressDetail?.id}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Comprobante: <b>{ingressDetail?.receipt_id}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Fecha creación:{" "}
                <b>{formatDateString(ingressDetail?.date_created)}</b>
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography>
                Proveedor: <b>{ingressDetail?.provider_name}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Deposito: <b>{ingressDetail?.warehouse_id}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Telefono: <b>{ingressDetail?.phone_number}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Email: <b>{ingressDetail?.email}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                Observaciones: <b>{ingressDetail?.observation}</b>
              </Typography>
            </Grid>
          </Grid>

          <ListadoDeProductos
            productos={ingressDetail.movement_details}
            withDeclared={true}
          />
        </>
      ) : (
        <>
          <List
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              padding: "2px",
            }}
          >
            <ListSubheader
              style={{ display: "flex", padding: "10px 0", fontWeight: "bold" }}
            >
              <Grid container>
                <Grid item xs={2}>
                  <Typography>
                    <b>SKU</b>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    <b>Descripción</b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography>
                    <b>
                      Imprimir <br /> etiqueta
                    </b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography>
                    <b>
                      Cantidad <br /> Declarada
                    </b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography>
                    <b>
                      Cantidad <br /> Ingresada
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </ListSubheader>

            {ingressDetail.movement_details.map((item) => (
              <ListItem key={item.product_id} style={{ padding: "10px 0" }}>
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="body2">{item.sku_id}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2">{item.description}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Checkbox
                      sx={{ p: 0, m: 0 }}
                      checked={
                        selectedProducts.some(
                          (selectedProduct) =>
                            selectedProduct.ean_id === item.ean_id
                        ) || item.print_tag === true
                      }
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2" align="center">
                      {item.original_count}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <TextField
                      variant="outlined"
                      key={item.product_id}
                      size="small"
                      type="text"
                      style={{ width: "80px" }}
                      value={amountEntered[item.product_id] || ""}
                      onChange={(event) =>
                        handleQuantityChange(event, item.product_id)
                      }
                      inputProps={{
                        pattern: "[0-9]*",
                        title: "Ingrese solo números",
                      }}
                      autoFocus={inputFocus === item.product_id}
                      error={erroresCantidad[item.product_id] !== null}
                      helperText={erroresCantidad[item.product_id]}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseDetailModal}
              style={{ marginRight: "16px" }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              {loading ? "Cargando..." : "Confirmar"}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};
export default ModalListadoIngresos;
