import axios from "axios";
import { getStorageItems } from "../utils/constants";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

client.interceptors.request.use(
  function (config) {
    const customData = getStorageItems();
    Object.assign(config.headers, customData);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  function (response) {
    return { ...response };
  },
  function (err) {
    const isLoginPage = window.location.pathname === "/login";

    if (err?.response?.status === 401 && !isLoginPage) {
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject(err);
  }
);

export default client;
